<template>
  <div class="toast">
    <img v-if="type=='error'"
      src="https://course-data-img.babybus.com/CourseData/CoursePic/20220810/4c73ee8ba1ca41d78465b44548d6e66e.png"
    />
    <img v-if="type=='normal'"
      src="https://course-data-img.babybus.com/CourseData/CoursePic/20220812/e05d7c2e3f8044a08cf13a9544de252b.png"
    />
    {{ msg }}
  </div>
</template>

<script>
import { useStore } from "@/store";
export default {
  name: "toast",
  components: {},
  data() {
    return {
      timer: null,
    };
  },
  props: {
    msg: String,
    type: String,
  },
  watch: {
    msg(str) {
      console.log("修改toast:", str);
      if (this.timer) clearTimeout(this.timer);
      this.moveOut();
    },
  },
  methods: {
    onLoaded() {
      this.isShow = true;
    },
    moveOut() {
      this.timer = setTimeout(() => {
        const store = useStore();
        store.toast = {
          isShow: false,
          msg: "",
          type: 'error'
        };
      }, 2000);
    },
  },
  mounted() {
    this.moveOut();
  },
};
</script>

<style lang="less" scoped>
.toast {
  padding: 16px 30px;
  background: rgba(19, 30, 60, 0.7);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.toast img {
  width: 64px;
  height: 64px;
  margin-right: 20px;
}
</style>

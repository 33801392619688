<template>
  <div class="wrapper">
    <Title></Title>
    <div class="wd_content" v-if="albumInfo.albumID">
      <div class="wd_info">
        <img src="https://educate-res.babybus.com/Educate/ResourceFile/20220413/03f995bd3e48444e9f37b734a0eb15b3.png" alt="" />

        <div class="img">
          <Image :src="albumInfo.coverUrl" background="#fff" />
        </div>
        <div style="flex: 1">
          <section class="wd_title">{{ albumInfo.albumName }}</section>
          <div style="display: flex">
            <section class="wd_describe" :class="albumInfo.albumDes && albumInfo.albumDes.length > 123 ? 'more' : ''">
              <label class="btn" @click="onTapShowDescribePopup" v-if="albumInfo.albumDes && albumInfo.albumDes.length > 123"></label>{{ albumInfo.albumDes }}
            </section>
          </div>
          <div class="wd_number">
            <img src="https://educate-res.babybus.com/Educate/ResourceFile/20220413/35d0af12d8364b9da72bf30c868b3cd2.png" />
            {{ albumInfo.audioCount }}个音频
          </div>
        </div>
      </div>
      <div class="wd_audio">
        <div
          class="audio_play"
          :style="currentAudio && currentAudio.audioID == audio.audioID && isPlaying ? 'background-color: rgba(230, 240, 255, .16)' : ''"
          v-for="(audio, index) of audioList"
          v-bind:key="audio.audioID"
        >
          <section class="audio1">
            {{ index + 1 > 9 ? index + 1 : "0" + (index + 1) }}
          </section>
          <section class="audio2 item-action" @click="currentAudio && currentAudio.audioID == audio.audioID && isPlaying ? '' : onTapPlay(index)">
            <section>{{ audio.audioName }}</section>
            <section>{{ audio.audioDes }}</section>
          </section>
          <section class="audio3">{{ audio.durationStr }}</section>

          <section class="audio4 item-action active" v-if="currentAudio && currentAudio.audioID == audio.audioID && isPlaying" @click="onTapPause(index)"></section>
          <section class="audio4 item-action" v-else @click="onTapPlay(index)"></section>

          <section class="audio5 item-action">
            <div class="action download-list" @click="onTapDownload(audio, index)">
              <span v-if="!audio.isDownloading">下载</span>
              <div v-if="audio.isDownloading" class="download-progress">
                <span>
                  <img src="https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/04FF741D-83F0-394D-2782-60242B17250B.gif" />
                </span>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="no-more" v-if="audioList.length">
        <section>到底了哦～</section>
      </div>
    </div>
    <div class="wd_noContent" v-show="showNetError">
      <Error type="net" />
    </div>
    <div class="wd_noContent" v-if="showContentError">
      <Error type="error" />
    </div>
  </div>
  <div class="describe-popup" v-show="isShowDescribePopup">
    <div class="d_wrap">
      <img class="bg" src="https://course-data-img.babybus.com/CourseData/CoursePic/20220728/9f2221099edf49168ff4090013359a5a.png" />
      <div class="btn-close" @click="onTapHideDescribePopup">
        <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220728/67878444a81f45b5a9f65b6ca666bb32.png" />
      </div>
      <div class="title">{{ albumInfo.albumName }}</div>
      <div class="line"></div>
      <div class="content">
        {{ albumInfo.albumDes }}
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/title/title.vue";
import BusinessService from "@/network/service";
import util from "@/utils/utils";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import BgAudioManager from "@/utils/audio-manager";
import Image from "@/components/image/image.vue";
import Cookies from "js-cookie";
import Error from "../../components/error/error.vue";
export default {
  name: "audio-detail",
  components: {
    Title,
    Image,
    Error,
  },
  data() {
    return {
      __id: "",
      albumInfo: {},
      audioList: [],
      currentAudio: {},
      isPlaying: false,
      isShowDescribePopup: false,
      showNetError: false,
      showContentError: false,
    };
  },
  methods: {
    //获取音频专辑详情
    getAlbumDetail() {
      BusinessService.getAlbumDetail(this.id)
        .then((res) => {
          console.log("获取音频专辑详情成功：", res);
          for (let audio of res.albumAudioInfoList) {
            audio.durationStr = util.formatSeconds(audio.duration);
          }
          let audiosDataInfo = sessionStorage.getItem(`audiosDataInfo`);
          if (audiosDataInfo) {
            audiosDataInfo = JSON.parse(audiosDataInfo);
          }
          if (audiosDataInfo[this.id]) {
            res.albumInfo = {
              ...res.albumInfo,
              ...audiosDataInfo[this.id],
            };
          }
          this.albumInfo = res.albumInfo;
          this.audioList = res.albumAudioInfoList;
          this.setTitle();
        })
        .catch((e) => {
          console.error("获取音频专辑详情失败：", e);
          this.showToast(e.resultMessage);
          if (e.code && e.code == "ERR_NETWORK") {
            this.showNetError = true;
          } else {
            this.showContentError = true;
          }
        });
    },

    onTapDownload(item, idx) {
      const store = useStore();
      const accountID = Cookies.get("AccountIDMD5") || store?.userInfo?.accountID;
      if (!accountID) {
        console.log("未登录");
        store.showLogin = true;
        return;
      }
      let audio = this.audioList[idx];
      this.audioList[idx].isDownloading = true;
      BusinessService.getAudioDownload({
        audioID: audio.audioID,
        albumID: audio.albumID,
      })
        .then((res) => {
          console.log("获取点读书列表页下载成功：", res);
          this.audioList[idx].isDownloading = false;
          try {
            util.downloadFile(res.url);
          } catch (err) {
            this.showToast(`下载失败，请稍后重试`);
          }
        })
        .catch((err) => {
          console.log("获取点读书列表页下载失败：", err);
          this.audioList[idx].isDownloading = false;
          this.showToast(err.resultMessage);
        });
    },
    onTapPlay(idx) {
      BgAudioManager.setData({
        audioList: this.audioList,
      });
      BgAudioManager.dispatchAudioListChangedListener();
      BgAudioManager.dispatchAudioPlayListener(idx, "play");
    },
    onTapPause(idx) {
      BgAudioManager.dispatchAudioPlayListener(idx, "pause");
    },
    setAudioList() {},
    setTitle() {
      const store = useStore();
      store.titleArray = [
        {
          name: "资源下载",
          path: "/list?id=1",
          backIdx: -1,
        },
        {
          name: "音频",
          path: "/list?id=2",
          backIdx: -1,
        },
        {
          name: this.albumInfo.albumName,
        },
      ];
    },
    onTapHideDescribePopup() {
      this.isShowDescribePopup = false;
      this.canWheel();
    },
    onTapShowDescribePopup() {
      this.isShowDescribePopup = true;
      this.noWheel();
    },
    noWheel() {
      const store = useStore();
      //store.isNoWheel = true;
    },
    canWheel() {
      const store = useStore();
      //store.isNoWheel = false;
    },
    initListener() {
      if (this.__id) return;
      this.__id = "player_" + +new Date();
      BgAudioManager.addCurrentAudioChangedListener(this.__id, (audio) => {
        this.currentAudio = audio;
      });
      BgAudioManager.dispatchCurrentAudioChangedListener();
      BgAudioManager.addStatusChangedListener(this.__id, (status) => {
        this.isPlaying = status == "play";
      });
      BgAudioManager.dispatchStatusChangedListener();
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
  },
  mounted() {
    let route = useRoute();
    this.id = route.params.id;
    this.getAlbumDetail();
    this.initListener();
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  // margin-left: calc((100% - 1000px) / 2) !important;
}
.wd_content {
  padding: 0 36px;
}
.wd_content > .wd_info {
  position: relative;
  display: flex;
  /* align-items: center; */
}
.wd_content > .wd_info > img:nth-of-type(1) {
  width: 259px;
  height: 216px;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 1;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}
.wd_content > .wd_info > .img {
  position: relative;
  z-index: 2;
  max-width: 216px;
  height: 216px;
  width: 216px;
  object-fit: contain;
  object-position: center;
  background-color: #f0f0f0 !important;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  margin-right: 44px;
  overflow: hidden;
}

.wd_title {
  width: 100%;
  margin-top: 17px;
  // padding: 0 18px;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.wd_content .wd_describe {
  width: 660px;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  word-break: break-all;
  position: relative;
  line-height: 22px;
  height: 66px;
  transition: 0.3s max-height;
  margin-top: 8px;
  white-space: pre-wrap;
}
.wd_content .more::before {
  content: "";
  float: right;
  width: 0px;
  height: 100%;
  margin-bottom: -21px;
}
.wd_content .wd_describe .btn {
  position: relative;
  float: right;
  clear: both;
  margin-left: 20px;
  font-size: 16px;
  padding: 0 8px;
  background: #fff;
  line-height: 24px;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
}
.wd_content .wd_describe .btn::after {
  content: "查看全部>>";
  text-decoration: underline;
}
.wd_content .wd_describe .btn::before {
  content: "...";
  position: absolute;
  left: -5px;
  color: #999;
  transform: translateX(-100%);
}
.wd_number {
  display: flex;
  align-items: center;
  // padding: 0 18px;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}
.wd_number > img {
  width: 17px;
  height: 16px;
  margin-right: 5px;
}
.wd_audio {
  margin-top: 16px;
}
.wd_audio .audio_play {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px rgba(217, 217, 217, 0.7) solid;
  cursor: pointer;
}
.wd_audio .audio_play:hover {
  border-bottom: 1px rgba(217, 217, 217, 0.4) solid;
  background-color: rgba(230, 240, 255, 0.16);
}
.play_active3 {
  background-color: rgba(230, 240, 255, 0.16) !important;
}
.audio_play .audio1 {
  width: 50px;
  font-size: 16px;
  color: #999999;
  text-align: center;
  margin-right: 20px;
}
.audio_play .audio2 {
  width: 560px;
  /* border: 1px solid red; */
}

.audio_play .audio2 > section:nth-of-type(1) {
  width: 540px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  color: #333333;
}
.audio_play:hover .audio2 > section:nth-of-type(1) {
  color: #009bf5;
}
.audio_play .audio2 > section:nth-of-type(2) {
  margin-top: 5px;
  font-size: 16px;
  padding-right: 20px;
  color: #999999;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.audio_play:hover .audio2 > section:nth-of-type(2) {
  opacity: 0.5;
}
.audio_play .audio3 {
  width: 43px;
  font-size: 16px;
  color: #999999;
  /* border: 1px solid red; */
}
.audio_play .active {
  background-image: url(https://picfile-baidu.babybus.com/ActivityData/BabyBusFile/20221121/cc9a584453f943d581b6add2bbad6119.png) !important;
}
.audio_play .active:hover {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220729/1928d0bf093a466f9bf204bd58282451.png) !important;
}
.audio_play .audio4 {
  width: 36px;
  height: 36px;
  margin-left: 48px;
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220729/1784c13f303d41a6879f55f8c17ec3f0.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.audio_play .audio4:hover {
  background-image: url(https://picfile-baidu.babybus.com/ActivityData/BabyBusFile/20221121/3862d8b824f84926a6d3dd3f1dfa671b.png);
}
.audio_play .audio5 {
  width: 92px;
  height: 36px;
  line-height: 36px;
  margin-left: 48px;
  background: #fa885a;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.audio_play .audio5:hover {
  background: #f26636;
}
.icon_series {
  width: 42px;
  height: 20px;
  background: #5cc8fa;
  border-radius: 2px 10px 10px 2px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  padding-bottom: 4px;
  padding-right: 2px;
}
.wrap-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.describe-popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 103;
}
.describe-popup .d_wrap {
  width: 606px;
  height: 440px;
  background: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 24px 8px 35px 35px;
  position: relative;
}
.describe-popup .d_wrap .title {
  width: 321px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.describe-popup .d_wrap .line {
  width: 536px;
  border: 1px solid rgba(217, 217, 217, 0.7);
  margin: 16px 0;
}
.describe-popup .d_wrap .content {
  width: 560px;
  height: 320px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  overflow: scroll;
  padding-right: 20px;
  word-break: break-all;
  scrollbar-color: #5cc8fa #fff;
  white-space: pre-wrap;
}
.describe-popup .d_wrap .content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.describe-popup .d_wrap .content::-webkit-scrollbar-thumb {
  background: #e6f0ff;
  border-radius: 3px;
}
.describe-popup .d_wrap .bg {
  width: 230px;
  height: 67px;
  right: 35px;
  top: 2px;
  position: absolute;
  z-index: 0;
}
.describe-popup .d_wrap .btn-close {
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 0;
  cursor: pointer;
}
.describe-popup .d_wrap .btn-close img {
  width: 100%;
  height: 100%;
}
.no-more {
  width: 80px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(153, 153, 153, 0.6);
  line-height: 22px;
  margin: 15px auto 0px;
  padding-bottom: 100px;
}
.wd_noContent {
  margin: 0px auto;
  padding-top: 120px;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "@/lang";
import rtlDirective from "./directives/rtl";
import { getRelativePath } from "./mixins/rPath";

const app = createApp(App);
const pinia = createPinia();

app.directive("rtl", rtlDirective);
app.use(router).use(pinia).mount("#app");
app.use(i18n);

// 全局混入
app.mixin({
  methods: {
    getRelativePath,
  },
});
console.log = (function (log) {
  return process.env["VUE_APP_ENV"] == "production" ? function () {} : log;
})(console.log);

<template>
  <div class="wd_titles" v-if="store.titleArray.length">
    {{ $t("我的位置") }}：
    <div v-for="item of store.titleArray" v-bind:key="item.name">
      <div class="item showPointer" v-if="item.path" @click="onTapPath(item.path)">{{ item.name }}&nbsp;&gt;</div>
      <!-- &gt; -->
      <div v-if="!item.path" class="item last">{{ item.name }}&nbsp;</div>
    </div>
  </div>
</template>

<script>
// 导入状态仓库
import { useStore } from "@/store";
export default {
  name: "title",
  components: {},
  data() {
    return {};
  },
  methods: {
    back(idx) {
      this.$router.go(idx);
    },
    onTapPath(path) {
      this.$router.push(path);
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.wd_titles {
  padding: 0 36px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
  white-space: nowrap;
  overflow: hidden;
}
.item {
  font-size: 14px;
  color: #999999;
  margin-left: 2px;
}
.last {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.showPointer {
  cursor: pointer;
}
.ative {
  color: #999999;
}
</style>

<template>
  <div>
    <div class="bg">
      <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220812/ff108ea0ac6b4ad3b999d8f0bc97d5ca.png" />
    </div>
    <!-- <div class="wrapper"></div> -->
    <div class="content">
      <p v-html="content" style="width: 100%; height: 100%"></p>
      <div class="wd_noContent" v-show="showError">
        <Error type="net" />
      </div>
    </div>
  </div>
</template>

<script>
import BusinessService from "@/network/service";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import Error from "../../components/error/error.vue";
export default {
  name: "protocol",
  components: {
    Error,
  },
  data() {
    return {
      content: "",
      url: "",
      showError: false,
    };
  },
  watch: {
    $route(to, from) {
      this.url = decodeURIComponent(to.params.url).replace("http://", "https://");
      this.initData();
    },
  },
  methods: {
    initData() {
      this.getDetail();
    },
    getDetail() {
      if (this.showError) this.showError = false;
      BusinessService.getProtocol(this.url)
        .then((res) => {
          console.log(res);
          this.content = res;
        })
        .catch((err) => {
          console.error(err);
          if (err && err.code) {
            this.showToast(err.resultMessage);
            this.showError = true;
          } else {
            this.content = err;
          }
        });
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
  },
  mounted() {
    let route = useRoute();
    this.url = decodeURIComponent(route.params.url).replace("http://", "https://");
    this.initData();
  },
};
</script>

<style lang="less" scoped>
.bg {
  display: flex;
  justify-content: center;
  background: #5cc8fa;
  width: 100%;
  position: relative;
}
.bg img {
  width: 1110px;
  height: 150px;
  background: #5cc8fa;
}
.bg::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 500px);
  width: 1000px;
  height: 30px;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.content {
  width: 1000px;
  margin-top: -30px;
  min-height: calc(100vh - 257px);
  background-color: #fff;
  margin-left: calc((100% - 1000px) / 2) !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 30px 120px;
}

.wd_noContent {
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding-top: 100px;
}
</style>

<template>
  <div class="page-bar">
    <ul class="pagination-bar" v-rtl v-if="pageCount && pageCount > 1">
      <!-- 上一页 -->
      <li class="page-item" v-if="pageIndex > 0" @click="onPrev">
        <div style="width: 64px">
          <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220726/e9e51dec11a74039a696c70918c6c266.png" />
          <span>{{ $t("上一页") }}</span>
        </div>
      </li>

      <li class="page-item" :class="pageIndex == index ? 'active' : ''" @click="onTapItem(index)" v-for="(item, index) of pageCount" v-bind:key="'page' + index">
        <div :class="pageIndex == index ? 'current' : ''">{{ index + 1 }}</div>
      </li>

      <!-- 下一页 -->
      <li class="page-item" v-if="pageIndex < pageCount - 1" @click="onNext">
        <div style="width: 64px">
          <span>{{ $t("下一页") }}</span>
          <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220726/7359a4b614344e089bfe8fd8adbfcd2b.png" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pageBar",
  data() {
    return {
      pageList: [],
    };
  },
  components: {},
  props: {
    pageCount: Number,
    pageIndex: Number,
  },
  methods: {
    onPrev() {
      this.$emit("setPageIndex", this.pageIndex - 1 < 0 ? 0 : this.pageIndex - 1);
    },
    onNext() {
      this.$emit("setPageIndex", this.pageIndex + 1 > this.pageCount.length ? this.pageCount.length : this.pageIndex + 1);
    },
    onTapItem(index) {
      this.$emit("setPageIndex", index);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.page-bar {
  margin: 0 auto;
  padding-bottom: 56px;
  border-radius: 3px;
  // background-color: #fff;

  .pagination-bar {
    display: flex;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;

    .page-item {
      text-align: center;
      margin-right: 8px;

      div {
        font-size: 12px;
        background: #ffffff;
        color: rgba(153, 153, 153, 1);
        overflow: hidden;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;

        span {
          margin: 0 2px;
        }
      }

      .current {
        color: #fff !important;
        border: 1px solid #64c8ff;
        background: #64c8ff !important;
      }
    }

    &.rtl {
      .page-item {
        img {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

li {
  list-style: none;
}
</style>

const ENV = process.env["VUE_APP_ENV"];

const ENV_CONFIG = {
  business: {
    host: {
      development: "https://bookpointapi.development.platform.babybus.com",
      staging: "https://bookpointapi.staging.platform.babybus.com",
      production: "https://bookpointapi.babybus.com",
    },
    host_global: {
      development: "https://bookpointapi.development.platform.babybus.com/Overseas",
      staging: "https://bookpointapi.staging.platform.babybus.com/Overseas",
      production: "https://bookpointapi-global.babybus.com/Overseas",
    },
  },
  appHelpCenter: {
    host: {
      development: "https://apphelpcenter.development.platform.babybus.com",
      staging: "https://apphelpcenter.staging.platform.babybus.com",
      production: "https://apphelpcenter.babybus.com",
    },
    host_global: {
      development: "https://apphelpcenter.development.platform.babybus.com",
      staging: "https://apphelpcenter.staging.platform.babybus.com",
      production: "https://apphelpcenter.babybus.com",
    },
  },
  agreementInfoUrl: "https://udb.babybus.com/AccountH5/AgreementInfo?ProductID=4023&verID=0",
  privacyServiceUrl: "https://udb.babybus.com/AccountH5/PrivacyService?ProductID=4023&verID=0",
  collectID: {
    book: {
      development: "cfa238bbc5914348b1be6ad3f1174534",
      staging: "c88cc560692a4689b7a59f93506a722a",
      production: "c88cc560692a4689b7a59f93506a722a",
    },
    audio: {
      development: "8831c6c6028f4a70a4c9b3fe07df9fed",
      staging: "9d84be336bea4f85a110ff57761d1bca",
      production: "9d84be336bea4f85a110ff57761d1bca",
    },
  },
  areaID: {
    book: {
      development: "04224aa0957c444eb82db132978011ea",
      staging: "04224aa0957c444eb82db132978011ea",
      production: "04224aa0957c444eb82db132978011ea",
    },
    book_global: {
      development: "8dbdac85fbff40d4b6a43f2597aea517",
      staging: "8dbdac85fbff40d4b6a43f2597aea517",
      production: "8dbdac85fbff40d4b6a43f2597aea517",
    },
  },
  usageID: {
    zh: {
      development: 867,
      staging: 867,
      production: 829,
    },
    ar: {
      development: 1745,
      staging: 1726,
      production: 1726,
    },
  },
  protocols: {
    agreement: {
      zh: "https://udb.babybus.com/AccountH5/AgreementInfo?ProductID=4023&verID=0",
      ar: "https://www.babybus.com/global/ar/agreement",
    },
    privacy: {
      zh: "https://udb.babybus.com/AccountH5/PrivacyService?ProductID=4023&verID=0",
      ar: "https://www.babybus.com/global/ar/policy",
    },
    about: {
      zh: "https://www.babybus.com/home",
      ar: "https://www.babybus.com/global/ar/about",
    },
  },
  questionListCategories: {
    zh: [
      {
        coverUrl: "https://iot.babybus.com/BookPoint/BookPic/20221129/718bd05cb2ab4fbdb98f4eb9b6bce26d.png",
        productCode: "A",
        productHelperCategoryCode: "A",
        productName: "宝宝巴士点读笔",
      },
      {
        coverUrl: "https://iot.babybus.com/BookPoint/BookPic/20221129/38c33526eccb417585fc8ade6ebabe70.png",
        productCode: "G",
        productHelperCategoryCode: "G",
        productName: "宝宝巴士点读笔",
      },
    ],
    ar: [
      {
        coverUrl: "https://picfile-baidu.babybus.com/BceFile/FileData/20240723/5131074bec24475f9205baf7fa517977.png",
        productCode: "G1",
        productHelperCategoryCode: "G1",
        productName: "宝宝巴士点读笔",
      },
    ],
  },
};

const isGlobalWeb = /global/gi.test(location.pathname);

const getAreaID = function (type) {
  if (isGlobalWeb) {
    return ENV_CONFIG.areaID[type + "_global"][ENV];
  } else {
    return ENV_CONFIG.areaID[type][ENV];
  }
};

const getCollectID = function (type) {
  if (isGlobalWeb) {
    return "";
  } else {
    return ENV_CONFIG.collectID[type][ENV];
  }
};

function getHost(type) {
  if (isGlobalWeb) {
    return ENV_CONFIG[type]["host_global"][ENV];
  } else {
    return ENV_CONFIG[type]["host"][ENV];
  }
}

function getUsageID(lang) {
  return ENV_CONFIG["usageID"][lang][ENV];
}

function getProtocol(type, lang) {
  return ENV_CONFIG.protocols[type][lang];
}

function getProductQuestionList(lang) {
  return ENV_CONFIG.questionListCategories[lang];
}

module.exports = {
  getAreaID,
  getCollectID,
  getHost,
  getUsageID,
  getProtocol,
  getProductQuestionList,
};

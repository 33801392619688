import request from "./request";
import requestNoCredentials from "./request-no-credentials";
import { getHost } from "./config";
import md5 from "../utils/md5.min.js";

const isLocalDev = false; //location.port === '8082';
const BusinessHost = isLocalDev ? "/book_api" : getHost("business");
const appHelpCenterHost = isLocalDev ? "/book_api" : getHost("appHelpCenter");

class BusinessService {
  // 获取页面数据
  getPageInfo() {
    return request({
      name: "页面中心-页面数据",
      url: `${BusinessHost}/PageData/GetPageInfo`,
      method: "GET",
      params: {
        pageID: "06275d793cb845088b0f0dd4bbeb7b58",
        pagesize: 99,
        pageindex: 0,
      },
    });
  }

  // 获取集合数据
  getCollectInfo(data) {
    let params = {};
    if (data.pageClassId == 0) {
      params = {
        collectID: data.collectID,
        pagesize: data.pagesize,
        pageindex: data.pageindex,
      };
    } else {
      params = {
        collectID: data.collectID,
        pagesize: data.pagesize,
        pageindex: data.pageindex,
        userTag: data.typeTag,
        ageTag: data.ageTag,
        typeTag: data.userTag,
      };
    }
    return request({
      name: "页面中心-集合数据",
      url: `${BusinessHost}/PageData/GetCollectInfoResult`,
      method: "GET",
      params,
    });
  }

  // 获取区域数据
  getAreaInfo(data) {
    let params = {
      areaID: data.areaID,
      pagesize: data.pagesize,
      pageindex: data.pageindex,
      lang: data.lang,
    };
    return request({
      name: "页面中心-区域数据",
      url: `${BusinessHost}/PageData/GetAreaResult`,
      method: "GET",
      params,
    });
  }

  // 搜索点读包数据
  searchBookInfo({ keyword, pagesize, pageindex }) {
    return request({
      name: "搜索-点读包",
      url: `${BusinessHost}/Book/Search`,
      method: "GET",
      params: {
        keyword: keyword,
        pagesize: pagesize || 12,
        pageindex: pageindex || 0,
      },
    });
  }

  // 搜索专辑数据
  searchAlbumInfo({ keyword, pagesize, pageindex }) {
    return request({
      name: "搜索-专辑",
      url: `${BusinessHost}/Audio/SearchAlbumInfo`,
      method: "GET",
      params: {
        keyword: keyword,
        pagesize: pagesize || 12,
        pageindex: pageindex || 0,
      },
    });
  }

  //【Web】书本详情页(单包和多包)
  getBookDetail(bookID) {
    return request({
      name: "点读包-实体书详情",
      url: `${BusinessHost}/Book/BookDetail`,
      method: "GET",
      params: {
        bookID: bookID,
      },
    });
  }

  //【Web】书本详情页(单包和多包)
  getBookAlbumDetail(albumID) {
    return request({
      name: "点读包-专辑详情",
      url: `${BusinessHost}/Book/AlbumDetail`,
      method: "GET",
      params: {
        albumID: albumID,
      },
    });
  }

  //【Web】书本详情页(单包和多包)
  getAlbumInfoDetail(albumID, lang) {
    return request({
      name: "包装书详情",
      url: `${BusinessHost}/Book/AlbumInfoDetail`,
      method: "GET",
      params: {
        albumID: albumID,
        lang,
      },
    });
  }

  //【web】音频专辑详情接口（音频列表）
  getAlbumDetail(albumid) {
    console.log({
      name: "音频-专辑详情",
      url: `${BusinessHost}/audio/albumdetail`,
      method: "GET",
      params: {
        albumid: albumid,
      },
    });
    return request({
      url: `${BusinessHost}/audio/albumdetail`,
      method: "GET",
      params: {
        albumid: albumid,
      },
    });
  }

  //【Web】获取搜索联想词（点读书）
  getPredictiveSearchWords(inputword) {
    return request({
      name: "搜索-获取联想词",
      url: `${BusinessHost}/Book/GetPredictiveSearchWords`,
      method: "GET",
      params: {
        inputword: inputword,
      },
    });
  }

  //批量下载包(点读包)
  getBookFileBatchUrl(bookID) {
    return request({
      name: "批量下载-点读包",
      url: `${BusinessHost}/Book/GetBookFileBatchUrl`,
      method: "GET",
      params: {
        bookID: bookID,
      },
    });
  }

  //批量下载包(专辑)
  getAlbumBookFileBatchUrl(albumID) {
    return request({
      name: "批量下载-专辑",
      url: `${BusinessHost}/Book/GetAlbumBookFileBatchUrl`,
      method: "GET",
      params: {
        albumID: albumID,
      },
    });
  }

  //包装书压缩包下载
  getAlbumZipFileUrl(albumID, lang) {
    return request({
      name: "包装书-下载",
      url: `${BusinessHost}/Book/GetAlbumZipFileUrl`,
      method: "GET",
      params: {
        albumID,
        lang,
      },
    });
  }

  //实体书压缩包下载
  getBookZipFileUrl(bookID, lang) {
    return request({
      name: "包装书-下载",
      url: `${BusinessHost}/Book/GetBookZipFileUrl`,
      method: "GET",
      params: {
        bookID,
        lang,
      },
    });
  }

  //下载单个文件
  getBookFileUrl(bookFileID) {
    return request({
      name: "下载-单文件",
      url: `${BusinessHost}/Book/GetBookFileUrl`,
      method: "GET",
      params: {
        bookFileID: bookFileID,
      },
    });
  }

  //【Web】获取音频地址
  getAudioUrl({ audioID, retryCount, high, albumID }) {
    return request({
      name: "音频-获取播放地址",
      url: `${BusinessHost}/Audio/AudioPlay`,
      method: "GET",
      params: {
        audioID: audioID,
        retryCount: retryCount || 0,
        high: high || 0,
        albumID: albumID,
      },
    });
  }

  //【Web】获取音频下载地址
  getAudioDownload({ audioID, retryCount, high, albumID }) {
    return request({
      name: "音频-获取下载地址",
      url: `${BusinessHost}/Audio/AudioDownload`,
      method: "GET",
      params: {
        audioID: audioID,
        retryCount: retryCount || 0,
        high: high || 0,
        albumID: albumID,
      },
    });
  }

  //【web】帮助与服务产品问题列表
  getProductQuestionList({ lang }) {
    // return request({
    //   name: "帮助中心-列表",
    //   url: `${BusinessHost}/Helper/ProductQuestionList`,
    //   method: "GET",
    //   params: {
    //     lang,
    //   },
    // });
  }

  //获取问题列表
  getQuestionList(url) {
    return request({
      name: "帮助中心-列表",
      url: url,
      method: "GET",
      params: {},
    });
  }

  //获取协议内容
  getProtocol(url) {
    return request({
      name: "帮助中心-协议内容",
      url: url,
      method: "GET",
      params: {},
    });
  }

  //【Web】获取登陆结果接口
  getLoginResult() {
    return request({
      name: "登录-获取登录结果",
      url: `${BusinessHost}/Account/GetLoginResult`,
      method: "GET",
      params: {},
    });
  }

  //【Web】登出
  loginOut() {
    return request({
      name: "登出",
      url: `${BusinessHost}/Account/LoginOut`,
      method: "GET",
      params: {},
    });
  }

  //【Web】手机发送验证码
  sendCaptcha(Phone) {
    let params = {
      Phone,
    };
    let url = `${BusinessHost}/Account/SendCaptcha?${concatSortParams(getQueryObject(params))}`;
    console.log(url);
    return request({
      url: url,
      method: "GET",
      dataType: "json",
      params: {},
    });
  }

  //【Web】手机登录
  loginPhone(Phone, CaptchaNo) {
    let params = {
      Phone,
      CaptchaNo,
    };
    let url = `${BusinessHost}/Account/LoginPhone?${concatSortParams(getQueryObject(params))}`;
    return request({
      name: "登录-手机号登录",
      url: url,
      method: "GET",
      params: {},
    });
  }

  // 问题-获取分类列表
  getCategoryInfos(params) {
    let url = `${appHelpCenterHost}/openapi/Question/GetCategoryInfos`;
    return request({
      url: url,
      method: "GET",
      params,
    });
  }

  // 问题-获取指定分类的问题列表
  getQuestionInfosByCategoryID(params) {
    let url = `${appHelpCenterHost}/openapi/Question/GetQuestionInfosByCategoryID`;
    return request({
      url: url,
      method: "GET",
      params,
    });
  }

  // 问题-获取设备列表
  getEntryInfos(params) {
    let url = `${appHelpCenterHost}/openapi/Question/GetEntryInfos`;
    return request({
      url: url,
      method: "GET",
      params,
    });
  }
}

export default new BusinessService();

function concatSortParams(queryParams) {
  // 获取排序key
  let sortkeys = allKeysSorted(queryParams);
  // 生成query数组
  let queryParis = [];
  let i = sortkeys.length;
  while (i--) {
    queryParis.push(sortkeys[i] + "=" + queryParams[sortkeys[i]]); //+encodeURIComponent(queryParams[sortkeys[i]]))
  }

  let query = queryParis.join("&");
  return query;
}

/**
 * 获取字典所有key，并按首字母升序排序（从小到大）
 *
 * @param dic 字典
 * @return 排序的keys
 */
function allKeysSorted(dic) {
  let sortkeys = [];
  for (let key in dic) {
    sortkeys.push(key);
  }

  sortkeys = sortkeys.sort(function (s, t) {
    var a = s.toLowerCase();
    var b = t.toLowerCase();
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  });

  return sortkeys;
}

function getQueryObject(params) {
  var queryObject = {
    ProductID: "4023",
    SignatureStamp: (+new Date()).toString(),
    EncryptType: 1, // 加密默认XxTea
    AccessToken: generateUUID(),
  };
  queryObject = {
    ...queryObject,
    ...params,
  };
  let queryStringWithoutMd5 = concatSortParams(queryObject);
  let queryMd5 = signMd5(queryStringWithoutMd5.toLocaleLowerCase(), true);
  queryObject["SignatureMD5"] = queryMd5;
  return queryObject;
}

function signMd5(queryParams, isNeedKey) {
  if (isNeedKey) {
    queryParams = queryParams.concat("ef57236bf908475782c2f7acfc1f3d0c");
  }
  return md5(queryParams);
}

function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime();
  if (typeof performance !== "undefined" && typeof performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

<template>
  <div class="w_block" @click="onTapAlbumItem()">
    <div class="b_img">
      <Image :src="data.picUrl||data.coverUrl" />
    </div>
    <div class="b_main">
      <div class="b_title">{{ data.title||data.albumName }}</div>
      <div class="b_describe">{{ data.albumSummary||data.albumDes }}</div>
      <div class="b_number">
        <img
          src="https://educate-res.babybus.com/Educate/ResourceFile/20220413/35d0af12d8364b9da72bf30c868b3cd2.png"
          alt=""
        />
        {{ data.audioCount }}个音频
      </div>
    </div>
  </div>
</template>

<script>
import Image from "../image/image.vue";
export default {
  name: "audio-item",
  components: {
    Image,
  },
  props: {
    data: Object,
  },
  methods: {
    onTapAlbumItem() {
      console.log("onTapAlbumItem", this.data);
      let audiosDataInfo = sessionStorage.getItem(`audiosDataInfo`);
      if(!audiosDataInfo){
        audiosDataInfo = {};
      }else{
        audiosDataInfo = JSON.parse(audiosDataInfo)
      }
      let data = this.data;
      audiosDataInfo[data.albumID] = {
        albumName: data.title||data.albumName,
        albumDes: data.albumSummary||data.albumDes,
        coverUrl: data.picUrl||data.coverUrl
      }
      sessionStorage.setItem(`audiosDataInfo`, JSON.stringify(audiosDataInfo));
      this.$router.push(`/audio/${this.data.albumID}`);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.w_block {
  width: 444px;
  height: 160px;
  padding: 16px;
  margin: 6px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  text-decoration: none !important;
  outline: none !important;
}

.w_block > .b_img {
  display: inline-block;
  /* min-width: 128px; */
  width: 128px;
  height: 128px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
.w_block > .b_main {
  flex: 1;
  overflow: hidden;
}
.w_block > .b_main > .b_title {
  width: 100%;
  margin-top: 13px;
  padding-left: 18px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: bold;
}
.w_block:hover > .b_main > .b_title{
  color: #009BF5;
}
.w_block > .b_main > .b_describe {
  width: 100%;
  min-height: 34px;
  padding: 8px 0 0px 18px;
  margin-bottom: 18px;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.w_block > .b_main > .b_number {
  display: flex;
  align-items: center;
  padding: 0 18px;
  color: #666;
  font-size: 14px;
}
.w_block > .b_main > .b_number > img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
</style>

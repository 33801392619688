import axios from 'axios';

const service = axios.create();

// Request interceptors
service.interceptors.request.use(
  (config) => {
    console.debug(`#### ${config.method} ${config.url} 开始请求...`, config.data);
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      console.debug('### 请求失败,url:', response.config.url);
      return Promise.reject(response);
    } else if (response.data?.resultCode != '0') {
      return Promise.reject(response.data);
    } else {
      console.debug('### 请求成功', response.config.url, response?.data.data);
      return response?.data.data || response;
    }
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);

export default service;
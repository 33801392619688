import axios from "axios";

const service = axios.create({
  withCredentials: true,
});

const langMatch = location.pathname.match(/^\/global\/(\w+)(\/[^\/]+)?/);
const lang = langMatch ? langMatch[1] : "";

// Request interceptors
service.interceptors.request.use(
  (config) => {
    console.debug(`【${config.name}】 开始请求...`, config.url);
    console.debug(`【${config.name}】 Post 参数`, config.params);
    config.headers = {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": lang,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const config = response.config; // 获取请求的配置信息
    if (response.status !== 200) {
      console.error(`【${config.name}】请求失败`, response.data.resultMessage);
      return Promise.reject(response);
    } else if (response.data?.resultCode != "0") {
      return Promise.reject(response.data);
    } else {
      console.debug(`【${config.name}】请求返回数据：`, response?.data.data);
      return response?.data.data || response;
    }
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);

export default service;

<template>
  <div class="searchTag" v-if="pageType == 'search' && recordCount > 0">
    <div class="line"></div>
    找到{{ recordCount }}个专辑
    <div class="line"></div>
  </div>
  <div class="all_block">
    <!-- v-bind:key="item.albumID"会有不释放的情况 暂不绑定key -->
    <AudioItem
      v-for="item in list"
      
      :data="item"
    ></AudioItem>
    <div class="wd_noContent" v-if="isRequest && list && list.length == 0">
      <Error type="none" />
    </div>
  </div>
  <PageBar
    v-if="pageCount"
    :pageCount="pageCount"
    :pageIndex="pageIndex"
    @setPageIndex="setPageIndex"
  ></PageBar>
</template>

<script>
import AudioItem from "../audio-item/audio-item.vue";
import PageBar from "../page-bar/page-bar.vue";
import Error from "../error/error.vue";
export default {
  name: "audio-list",
  components: {
    AudioItem,
    PageBar,
    Error,
  },
  props: {
    list: Array,
    pageCount: Number,
    pageIndex: Number,
    isRequest: Boolean,
    recordCount: Number,
    pageType: String,
  },
  methods: {
    setPageIndex(index) {
      this.$emit("setPageIndex", index);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.all_block {
  margin-top: 10px;
  margin-left: 42px;
  display: flex;
  flex-wrap: wrap;
}
.wd_noContent {
  margin: 120px auto;
}
.searchTag {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cfcfcf;
  line-height: 22px;
  margin: 16px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchTag .line {
  width: 13px;
  height: 1px;
  background-color: #cfcfcf;
  margin: 0 8px;
}
</style>

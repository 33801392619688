// directives/rtl.js
import { useStore } from "@/store"; // 根据你的项目路径调整

export default {
  mounted(el) {
    const store = useStore();
    if (store.lang === "ar") {
      el.classList.add("rtl");
    }

    // 监听 lang 的变化
    store.$subscribe((mutation, state) => {
      if (state.lang === "ar") {
        el.classList.add("rtl");
      } else {
        el.classList.remove("rtl");
      }
    });
  },
  updated(el) {
    const store = useStore();
    if (store.lang === "ar") {
      el.classList.add("rtl");
    } else {
      el.classList.remove("rtl");
    }
  },
};

<template>
  <div class="btn_search">
    <div class="search_input">
      <div class="icon"></div>
      <!-- onblur="getBlur()"
        onfocus="getInput(event,'BookInfo')"
        onkeydown="goSearchKeyDown('BookInfo')" -->
      <input
        type="text"
        id="box"
        :placeholder="pageClassId == 1 ? '请输入音频名称' : '请输入书名'"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @keypress="onKeyPress"
        v-model="searchKey"
        autocomplete="off"
      />
      <div @click="onTapClear" :style="isShowDel ? 'display:inline-block' : ''" class="isShow"></div>
      <div class="go_search" @click="onTapSearch">
        <span v-if="!isSearching">搜索</span>
        <div v-if="isSearching" class="download-progress">
          <span>
            <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220804/c7ca3065ee824256b4e390c3cc311550.gif" />
          </span>
        </div>
      </div>
    </div>
    <!-- 目前只有点读包有联想 -->
    <div class="search_content" v-show="isFocus && pageClassId == 0 && predictiveList && predictiveList.length">
      <section class="btn_data" v-for="item of predictiveList" v-bind:key="item" @click="onClickPredictiveItem(item)">
        <span v-for="(w, idx) of item.wordArr" v-bind:key="w"
          >{{ w }}<span class="pe" v-if="idx != item.wordArr.length - 1">{{ predictiveKey }}</span></span
        >
      </section>
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
import BusinessService from "@/network/service";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
export default {
  name: "search",
  data() {
    return {
      isShowDel: false,
      searchKey: "",
      predictiveKey: "",
      predictiveList: [],
      isFocus: false,
    };
  },
  watch: {
    freshSearch() {
      this.searchKey = "";
      this.isShowDel = false;
      this.predictiveList = [];
    },
  },
  props: {
    pageClassId: Number,
    isSearching: Boolean,
    freshSearch: Boolean,
  },
  methods: {
    //点读笔包搜索联想
    getPredictiveSearchWords(key) {
      if (this.pageClassId != 0) return;
      if (this.searchKey.length == 0) {
        this.predictiveList = [];
        return;
      }
      console.log(key);
      BusinessService.getPredictiveSearchWords(key)
        .then((res) => {
          console.log("获取点读笔包搜索联想成功", res);
          if (this.searchKey != key) return;

          if (res.predictiveWords && res.predictiveWords.length) {
            for (let item of res.predictiveWords) {
              item.wordArr = item.wordName.split(key);
              if (item.wordName.indexOf(key) == 0) {
                //item.wordArr.splice(0, 0, "");
              } else if (item.wordName.indexOf(key) >= item.wordName.length - key.length) {
                //item.wordArr.push("");
              }
            }
            this.predictiveKey = this.searchKey + "";
            this.predictiveList = res.predictiveWords;
          }
        })
        .catch((e) => {
          this.predictiveList = [];
          this.predictiveKey = "";
          //console.error("获取点读笔包搜索联想失败", e);
        });
    },
    showToast(msg) {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg,
      };
    },
    onInput(e) {
      let searchKey = e.target.value;
      if (searchKey.length == 0 && this.isShowDel) {
        this.isShowDel = false;
      } else if (searchKey.length > 0 && !this.isShowDel) {
        this.isShowDel = true;
      }

      if (searchKey.length) {
        this.$emit("onInputSearch", searchKey);
      }
      this.searchKey = searchKey;
      this.getPredictiveSearchWords(searchKey);
    },
    onKeyPress(e) {
      if (e.keyCode == 13) {
        this.isFocus = false;
        this.$emit("onTapSearch", this.searchKey);
      }
    },
    onTapSearch() {
      this.$emit("onTapSearch", this.searchKey);
    },
    onClickPredictiveItem(e) {
      console.log("onClickPredictiveItem:", e);
      this.$router.push({ path: `/book/${e.refAlbumID}-1` });
    },

    onTapClear() {
      this.searchKey = "";
      this.isShowDel = false;
      this.$emit("onInputClear", "");
    },
    onBlur() {
      setTimeout(() => {
        this.isFocus = false;
      }, 350);
    },
    onFocus() {
      this.isFocus = true;
      this.getPredictiveSearchWords(this.searchKey);
    },
  },
  mounted() {
    watch([() => this.pageClassId], ([newVal], [oldVal]) => {
      //注意:此时的第一个参数是一个数组
      //组合监听,监听对象中的所有属性
      if (oldVal != newVal) {
        this.predictiveList = [];
      }
    });

    let route = useRoute();
    let { keyword } = route.query;
    if (keyword) {
      this.searchKey = keyword;
      this.isShowDel = true;
    }
  },
};
</script>

<style lang="less" scoped>
.btn_search {
  width: 1000px;
  height: 80px;
  background-color: #ffffff;
  position: relative;
  border-top: 1px solid #fafafa;
}
.search_input {
  width: 680px;
  height: 48px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background: #fafafa;
  margin: 16px auto 0;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}
.search_input .icon {
  width: 30px;
  height: 30px;
  background-image: url(https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/08655f1524764044860219509e5d9777.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 16px;
}
.search_input input {
  width: 495px;
  height: 100%;
  padding: 5px 10px 5px 5px;
  font-size: 16px;
  /* border: 1px solid black; */
  background: #fafafa;
}
.search_input input::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 0.6);
}
.search_input div:nth-of-type(2) {
  width: 30px;
  height: 30px;
  background-image: url("https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/b3750edcc53c45248648d532ff23eb16.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.btn_search .isShow {
  display: none;
}
.search_input div:nth-of-type(3) {
  width: 100px;
  height: 100%;
  background-color: #5cc8fa;
  color: white;
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.btn_search .search_content {
  position: absolute;
  width: 582px;
  height: auto;
  left: 158px;
  z-index: 10;
  background-color: #ffffff;
  padding: 12px 0 12px 0;
  top: 65px;
  border-radius: 8px;
}
.search_content .btn_data {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #999999;
  padding: 0px 15px 0px 50px;
  cursor: pointer;
}
.search_content .btn_data .pe {
  color: #fa885a;
}
.search_content .btn_data:hover {
  background-color: #f8fdff;
  color: #64c8ff;
}
.search_content .btn_data:hover .pe {
  color: #64c8ff;
}
</style>

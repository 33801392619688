// 想要使用必须先引入 defineStore；
import { defineStore } from "pinia";
// 这里我们使用的是es6 的模块化规范进行导出的。

// defineStore 方法有两个参数，第一个参数是模块化名字（也就相当于身份证一样，不能重复）

// 第二个参数是选项，对象里面有三个属性，相比于vuex 少了一个 mutations.
export const useStore = defineStore("main", {
  state() {
    // 存放的就是模块的变量
    return {
      titleArray: [],
      audioList: [],
      audioIndex: 0,
      showLogin: false,
      userInfo: {},
      isNoWheel: false, //禁止鼠标滚轮滚动
      toast: {
        isShow: false,
        msg: "",
        type: "error",
      },
      lang: "zh", //当前语言
    };
  },
  getters: {
    isGlobalWeb: (state) => state.lang && state.lang != "zh", //是否海外版
  },
  actions: {
    // 可以通过actions 方法，改变 state 里面的值。
  },
});

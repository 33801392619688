<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script></script>
<style>
/**
reset css
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none !important;
  outline: none !important;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

input,
textarea {
  font-family: PingFangSC-Regular, Tahoma, Microsoft Yahei, sans-serif, Arial;
  font-size: 16px;
  border: none;
  outline: none;
}

html,
body {
  font-family: PingFangSC-Regular, Tahoma, Microsoft Yahei, sans-serif, Arial;
  background-color: #f6f6f6;
}

html {
  width: 100%;
  /*height: 100%;*/
  overflow-y: scroll;
}

body {
  width: 100%;
  /*height: 100%;*/
  box-sizing: border-box;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
canvas {
  display: block;
}
</style>

<template>
  <div class="wrapper">
    <div class="helpContent">
      <div class="help-title">
        {{ $t("帮助与服务") }}
        <img src="https://iot.babybus.com/BookPoint/BookPic/20220601/46eafd5599f24ad99bc19ef24f903613.png?w=462&amp;h=136" alt="" />
      </div>
      <div class="hs_content">
        <div class="hs_left" :style="`height:${helpList.length * 86 + 18}px`">
          <section
            @click="onTapProduct(item)"
            :class="item.productCode == currentProduct.productCode ? 'hs_active' : ''"
            v-for="item of helpList"
            v-bind:key="item.deviceProductID"
          >
            <div class="img" v-if="item.coverUrl">
              <Image :src="item.coverUrl" />
            </div>
            <img v-else src="https://course-data-img.babybus.com/CourseData/CoursePic/20220803/59ee6ac0672f4c15bb4a0181db14f6af.png" />
            <div class="productName">{{ $t(item.productName) }}·{{ item.productCode }}</div>
          </section>
        </div>
        <div class="hs_right">
          <div class="list" :style="list.questionInfos.length ? '' : 'padding:0px'" v-for="list of questionList" v-bind:key="list.categoryID">
            <div class="list-name" v-if="list.questionInfos.length">{{ list.categoryName }}</div>
            <section class="item" :to="'/help'" v-for="item of list.questionInfos" v-bind:key="item.questionID" @click="onTapHelp(item)">
              <img v-if="item.iconUrl" class="icon" :src="item.iconUrl" />
              <p>{{ item.title }}</p>
              <div class="content-tag" :class="tag == '视频' ? 'blue-tag' : 'orange-tag'" v-for="tag of item.contentTags" v-bind:key="tag">
                {{ tag }}
              </div>
              <img class="icon-arrow" v-rtl src="https://iot.babybus.com/BookPoint/BookPic/20220601/c1be323d0cf147ec98d7ed504a37e80c.png?w=72&amp;h=72" />
            </section>
          </div>

          <div class="wd_noContent" v-show="showNetError">
            <Error type="net" />
          </div>
          <div class="wd_noContent" v-if="showContentError">
            <Error type="error" />
          </div>
          <div class="wd_noContent" v-if="!questionList.length && isRequest && !showNetError && !showContentError">
            <Error type="none" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessService from "@/network/service";
import { useRoute } from "vue-router";
import Image from "@/components/image/image.vue";
import { useStore } from "@/store";
import Error from "../../components/error/error.vue";
import { getHost, getProductQuestionList } from "@/network/config";
export default {
  name: "helper-list",
  components: {
    Image,
    Error,
  },
  data() {
    return {
      helpList: [],
      questionList: [],
      currentProduct: {},
      currentProductID: 0,
      isRequest: false,
      showNetError: false,
      showContentError: false,
    };
  },
  setup() {
    const store = useStore();
    console.log("store:", store);
    return {
      store,
    };
  },
  methods: {
    initData() {
      this.getProductQuestionList();
    },
    getProductQuestionList() {
      let params = {
        lang: this.store.lang == "zh" ? "" : this.store.lang,
      };
      const productList = getProductQuestionList(this.store.lang);
      this.currentProduct = productList[0];
      this.helpList = productList;
      this.onTapProduct(this.currentProduct);
      // BusinessService.getProductQuestionList(params)
      //   .then((res) => {
      //     console.log("获取本地分类列表成功：", res);
      //     this.helpList = res.productList;
      //     if (res.productList.length) {
      //       if (this.currentProductID) {
      //         for (let product of res.productList) {
      //           if (product.deviceProductID == this.currentProductID) {
      //             this.currentProduct = product;
      //           }
      //         }
      //       }
      //       if (!this.currentProduct || !this.currentProduct.deviceProductID) {
      //         this.currentProduct = res.productList[0];
      //       }

      //       // this.getQuestionList(this.currentProduct.questionListUrl);
      //       this.onTapProduct(this.currentProduct);

      //       if (this.showNetError) this.showNetError = false;
      //       if (this.showContentError) this.showContentError = false;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("获取帮助服务列表失败：", err);
      //     this.showToast(err.resultMessage);
      //     if (err.code && err.code == "ERR_NETWORK") {
      //       this.showNetError = true;
      //     } else {
      //       this.showContentError = true;
      //     }
      //   });
    },
    //获取问题列表
    getQuestionList(url) {
      this.isRequest = false;
      if (!url) return console.error("未配置问题列表");
      BusinessService.getQuestionList(url)
        .then((res) => {
          console.log("获取问题列表成功：", res);
          for (let i = 0; i < res.categoryInfos.length; i++) {
            if (!res.categoryInfos[i].questionInfos.length) {
              res.categoryInfos.splice(i, 1);
              i--;
            }
          }
          this.questionList = res.categoryInfos;
          this.isRequest = true;
          if (this.showNetError) this.showNetError = false;
          if (this.showContentError) this.showContentError = false;
        })
        .catch((err) => {
          console.log("获取问题列表失败：", err);
          this.showToast(err.resultMessage);
          this.isRequest = true;
          this.questionList = [];
          if (err.code && err.code == "ERR_NETWORK") {
            this.showNetError = true;
          } else {
            this.showContentError = true;
          }
        });
    },
    onTapProduct(product) {
      this.currentProduct = product;
      let host = getHost("appHelpCenter");
      let url = `${host}/openapi/Question/GetCategoryInfos?ProductID=4023&loadQuestionInfos=1&Transfer_Template=Frame&entryCode=${product.productCode}`;
      const { lang, isGlobalWeb } = this.store;
      if (isGlobalWeb & (lang != "zh")) url += `&Region=Overseas&AppLang=${lang}`;
      // "https://apphelpcenter.development.platform.babybus.com/openapi/Question/GetCategoryInfos?ProductID=4023&loadQuestionInfos=1&Transfer_Template=Frame&entryCode=A"

      this.getQuestionList(url);
    },

    onTapHelp(item) {
      let url = encodeURIComponent(item.contentUrl);
      let productName = encodeURIComponent(this.currentProduct.productName);
      let productID = this.currentProduct.deviceProductID;
      this.$router.push(
        `${this.getRelativePath("/help")}/${url}?title=${item.title}&productname=${productName}&productid=${productID}&Transfer_IsHiddenTitle=1&Transfer_IsHiddenReply=1`
      );
    },

    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
    setTitle() {
      const store = useStore();
      store.titleArray = [];
    },
  },
  mounted() {
    let route = useRoute();
    let id = route.query.id;
    if (id) {
      this.currentProductID = id;
    }
    this.initData();
    this.setTitle();
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 1000px;
  background-color: #fafafa;

  // margin-left: calc((100% - 1000px) / 2) !important;
}

.helpContent {
  width: 1000px;
  background-color: #fafafa;

  .help-title {
    width: 100%;
    height: 67px;
    color: #333333;
    font-size: 20px;
    padding: 0 50px;
    position: relative;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > img {
      width: 231px;
      height: 68px;
    }
  }
}

.hs_content {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;

  .hs_left {
    width: 216px;
    height: auto;
    background: #fff;
    border-radius: 8px;
    padding: 18px 0;
    overflow: hidden;

    > section {
      width: 100%;
      height: 68px;
      display: flex;
      font-size: 14px;
      align-items: center;
      color: #999999;
      background-color: white;
      cursor: pointer;
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }

      .productName {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        color: #009bf5;
      }

      > .img {
        width: 40px;
        height: 40px;
        margin-left: 20px;
        margin-right: 5px;
        border-radius: 10px;
        overflow: hidden;
        background: #f0f0f0;
      }
    }

    .hs_active {
      background-color: rgba(100, 200, 255, 0.08) !important;
      color: black !important;

      &:hover {
        color: #009bf5 !important;
      }
    }
  }

  .hs_right {
    width: 672px;
    background-color: white;
    min-height: calc(100vh - 286px);
    border-radius: 8px;

    .list {
      padding: 20px 17px;

      .list-name {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .item {
        position: relative;
        color: #333333;
        font-size: 14px;
        padding: 22px 0;
        border-bottom: 1px solid rgba(217, 217, 217, 0.4);
        cursor: pointer;
        display: flex;
        align-items: center;

        > p {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
        }

        &:hover > p {
          color: #009bf5;
        }
        .icon {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }

        .icon-arrow {
          display: inline-block;
          width: 24px;
          height: 24px;
          right: 0;
          &.rtl {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}

.content-tag {
  width: 40px;
  height: 18px;
  border-radius: 9px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 17px;
  margin: 0 2px;
  text-align: center;

  &.blue-tag {
    color: #5cc8fa;
    border: 1px solid #5cc8fa;
  }

  &.orange-tag {
    color: #fa885a;
    border: 1px solid #fa885a;
  }
}

p {
  margin: 0;
  padding: 0;
}

.wd_noContent {
  margin: 120px auto;
}
</style>

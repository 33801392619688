<template>
  <div class="page_class">
    <div :class="id == 0 ? 'active' : ''" @click="onClickPageClass(0)">点读书</div>
    <div :class="id == 1 ? 'active' : ''" @click="onClickPageClass(1)">音频</div>
  </div>
</template>

<script>
export default {
  name: "pageClass",
  props: {
    id: Number,
  },
  components: {},
  methods: {
    onClickPageClass(e) {
      this.$emit("onClickPageClass", e);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.page_class {
  position: sticky;
  width: 1000px;
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 75px;
  left: calc((100% - 1000px) / 2);
  z-index: 999;
}
.page_class > .active {
  color: #000e48;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px #64c8ff solid;
}
.page_class > div {
  width: 80px;
  height: 100%;
  line-height: 80px;
  color: #666666;
  font-size: 18px;
  margin: 0 40px;
  text-align: center;
  cursor: pointer;
}
</style>

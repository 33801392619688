<template>
  <div class="wrapper" :style="{ 'min-height': store.isGlobalWeb ? 'calc(100vh - 88px - 75px)' : 'calc(100vh - 112px - 75px)' }">
    <Title></Title>
    <div class="wd_content" v-if="bookInfo.bookID || bookInfo.albumID">
      <div class="wd_info">
        <div class="img-wrap" v-rtl>
          <Image :src="bookInfo.picList[imgCurrentIndex] || bookInfo.coverUrl" background="#fff" />
          <div class="wd_img" id="img-list" v-rtl ref="listWrap" v-if="bookInfo.picList.length">
            <div class="list-shadow-left" v-if="bookInfo.picList.length > 5 && imgCurrentIndex != 0">
              <img src="@/assets/icon_arrow_left_black.png" class="icon-arrow" @click.stop="onPrev(imgCurrentIndex)" />
            </div>
            <div class="list-shadow-right" v-if="bookInfo.picList.length > 5 && imgCurrentIndex != bookInfo.picList.length - 1" @click.stop="onNext(imgCurrentIndex)">
              <img src="@/assets/icon_arrow_right_black.png" class="icon-arrow" />
            </div>
            <div class="wd_img_list" :style="` justify-content: ${bookInfo.picList.length > 5 ? 'flex-start' : 'center'}`" ref="imgWrap">
              <!-- <div class="img" :class="imgCurrentIndex == -1 ? 'action' : ''" @click="onTapImage(-1, $event)" ref="img">
                <Image :src="bookInfo.coverUrl" background="#fff"></Image>
              </div> -->
              <div
                class="img"
                :ref="`img_${index}`"
                :class="index == imgCurrentIndex ? 'action' : ''"
                v-for="(imgUrl, index) of bookInfo.picList"
                v-bind:key="imgUrl"
                @click="onTapImage(index, $event)"
              >
                <Image :src="imgUrl" background="#fff"></Image>
              </div>
            </div>
          </div>
          <div v-if="showError" class="img-error"></div>
        </div>
        <div style="flex: 1">
          <!-- 标题 -->
          <div class="wd_title_wrap">
            <section class="wd_title">
              <div class="icon_series" v-rtl :style="`background:${bookInfo.albumTagFillColor || '#5cc8fa'}`" v-if="bookInfo.isSeries == 1 || bookInfo.albumTag">
                {{ bookInfo.albumTag || "系列" }}
              </div>
              {{ bookInfo.bookName || bookInfo.albumName }}
            </section>
          </div>

          <!-- 报错 -->
          <div class="error-wrap" v-if="showError">
            <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220816/f98d4397e42b4fa1afe89dca6f99e83c.png" />
            <p>资源即将上线，敬请期待</p>
            <div></div>
          </div>

          <!-- 更新日期 文件大小 -->
          <div class="wd_desc">
            {{ $t("更新日期") }}：
            <div class="wd_time" v-if="bookInfo.updateDate">
              {{ formatDate(bookInfo.updateDate) }}
            </div>
            <div class="wd_line"></div>
            {{ $t("文件大小") }}：
            <div class="wd_size" v-if="bookInfo.totalFileSize != undefined">
              {{ formatSize(bookInfo.totalFileSize) }}
            </div>
          </div>

          <!-- 下载按钮 -->
          <div class="wrap-flex" v-if="!showError">
            <!-- 多个点读包 -->
            <section class="wd_down item-action" @click="onTapDownloadBatchByAlbum">
              <div class="action download-list">
                <span v-if="!isDownloading">{{ fileList.length > 1 ? $t("下载全部") : $t("下载") }}</span>
                <div v-show="isDownloading" class="download-progress">
                  <span>
                    <img src="https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/04FF741D-83F0-394D-2782-60242B17250B.gif" />
                  </span>
                </div>
              </div>
            </section>

            <!-- 百度网盘 -->
            <section class="wd_bd item-action" v-if="bookInfo.baiduDirLink && !store.isGlobalWeb" @click="onTapBaiDu">
              <div class="action download-list">
                <span>百度网盘下载</span>
              </div>
            </section>

            <div v-if="!fileList.length"></div>
          </div>

          <div class="zip-explain" v-rtl>
            <p>
              {{ $t("下载提示") }}<span class="nav" @click="onTapHelp">{{ $t("点读包下载说明") }}</span>
            </p>
          </div>
        </div>
      </div>

      <div v-if="fileList.length > 1" class="down-wrap" :style="`margin-top:${bookInfo.picList.length ? '122' : '40'}px`">
        <div class="title">{{ $t("点读书列表") }}</div>
        <div class="item-down wd_down" v-for="(file, index) of fileList" v-bind:key="file.bookFileID">
          <div>{{ index >= 9 ? index + 1 : "0" + (index + 1) }}</div>
          <div class="flex-wrap">
            <!-- <img class="item-img" v-if="file.coverUrl" :src="file.coverUrl" /> -->
            <!-- <div class="item-img" v-if="file.coverUrl">
              <Image :src="file.coverUrl" />
            </div> -->
            <div>
              <div class="item-name">{{ file.bookName }}</div>
              <div class="item-sizeTime">
                <div class="item">
                  {{ $t("更新日期") }}：
                  <span class="item-time">{{ formatDate(file.updateDate) }}</span>
                </div>
                <div class="item">
                  {{ $t("文件大小") }}：
                  <span class="item-size">{{ formatSize(file.bookFileSize) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item-downLoad" @click="onTapDownloadBatchByBook(file.bookID)">
            <span v-if="!file.isDownloading"> {{ $t("下载") }}</span>
            <div v-show="file.isDownloading" class="download-progress">
              <span>
                <img src="https://picfile-baidu.babybus.com/ToolCenter/PromoteFile/04FF741D-83F0-394D-2782-60242B17250B.gif" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="no-more" v-if="fileList.length && fileList.length > 1">
        <section>{{ $t("到底了哦") }}</section>
      </div>
    </div>
    <div class="describe-popup" v-show="isShowDescribePopup">
      <div class="d_wrap">
        <img class="bg" src="https://course-data-img.babybus.com/CourseData/CoursePic/20220728/9f2221099edf49168ff4090013359a5a.png" />
        <div class="btn-close" @click="onTapHideDescribePopup">
          <img src="https://course-data-img.babybus.com/CourseData/CoursePic/20220728/67878444a81f45b5a9f65b6ca666bb32.png" />
        </div>
        <div class="title">{{ bookInfo.bookName }}</div>
        <div class="line"></div>
        <div class="content">
          {{ bookInfo.summary }}
        </div>
      </div>
    </div>
    <div class="wd_noContent" v-show="showNetError">
      <Error type="net" />
    </div>
    <div class="wd_noContent" v-if="showContentError">
      <Error type="error" />
    </div>
  </div>
</template>

<script>
import Title from "../../components/title/title.vue";
import BusinessService from "@/network/service";
import util from "@/utils/utils";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import Image from "@/components/image/image.vue";
import Cookies from "js-cookie";
import Error from "../../components/error/error.vue";
import { getHost, getUsageID } from "@/network/config";
export default {
  name: "book-detail",
  components: {
    Title,
    Image,
    Error,
  },
  data() {
    return {
      id: 0,
      isAlbum: 0, //0点读包 1点读包专辑
      bookInfo: {},
      fileList: [],
      fileType: "normal",
      isDownloading: false,
      isShowDescribePopup: false,
      showError: false,
      showNetError: false,
      showContentError: false,
      downloadTimer: null,
      imgCurrentIndex: 0,
      imgTransFromX: 0,
      pageX: 0,
      isMoveImageList: false,
      isShowShadow: false,
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    formatDate() {
      const store = useStore();
      return (str) => {
        const date = new Date(str);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() 返回的月份从 0 开始，所以要加 1
        const year = date.getFullYear();

        return store.isGlobalWeb ? `${day}/${month}/${year}` : `${year}-${month}-${day}`;
      };
    },
    formatSize() {
      return (fileSize) => {
        const size = Number(fileSize);
        if (!size) return `0 ${this.$t("MB")}`;
        if (size < 1024) {
          return `${size} B`;
        } else if (size < 1024 * 1024) {
          return `${(size / 1024).toFixed(2)} KB`;
        } else if (size < 1024 * 1024 * 1024) {
          return `${(size / (1024 * 1024)).toFixed(2)} ${this.$t("MB")}`;
        } else {
          return `${(size / (1024 * 1024 * 1024)).toFixed(2)} ${this.$t("GB")}`;
        }
      };
    },
  },
  methods: {
    initData() {
      this.getAlbumDetail();
    },

    getAlbumDetail() {
      BusinessService.getAlbumInfoDetail(this.id, this.store.lang)
        .then((res) => {
          console.log("getAlbumInfoDetail:", res);
          res.albumInfo.picList = res.albumInfo.previewPicList ? res.albumInfo.previewPicList.split("$") : [];
          // 如果有轮播时，把封面插到轮播的第一张
          if (res.albumInfo.picList && res.albumInfo.picList.length > 0) {
            res.albumInfo.picList.unshift(res.albumInfo.coverUrl);
          }
          // res.albumInfo.totalFileSize &&
          //   (res.albumInfo.totalFileSize =
          //     res.albumInfo.totalFileSize.toFixed(2));
          this.bookInfo = res.albumInfo;
          this.fileList = res.bookList;
          if (res.albumInfo.fileType) this.fileType = res.albumInfo.fileType;
          this.setTitle();

          if (!res.albumInfo.fileUrl && (!res.bookList.length || res.albumInfo.totalFileSize == 0)) {
            this.showError = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.showToast(err.resultMessage);
          if (err.code && err.code == "ERR_NETWORK") {
            this.showNetError = true;
          } else {
            this.showContentError = true;
          }
        });
    },
    //实体书下载
    onTapDownloadBatchByBook(bookID) {
      if (this.downloadTimer) return;
      this.downloadTimer = setTimeout(() => {
        clearTimeout(this.downloadTimer);
        this.downloadTimer = null;
      }, 2000);
      const store = useStore();
      const accountID = Cookies.get("AccountIDMD5") || store?.userInfo?.accountID;
      if (!accountID && !store.isGlobalWeb) {
        console.log("未登录");
        store.showLogin = true;
        return;
      }
      this.isDownloading = true;
      BusinessService.getBookZipFileUrl(bookID, store.lang)
        .then((res) => {
          console.log("实体书下载成功：", res);
          this.isDownloading = false;
          util.downloadFile(res.fileUrl);
        })
        .catch((err) => {
          console.log("实体书下载失败：", err);
          this.isDownloading = false;
          this.showToast(err.resultMessage);
        });
    },
    onTapDownloadBatchByAlbum() {
      if (this.downloadTimer) return;
      this.downloadTimer = setTimeout(() => {
        clearTimeout(this.downloadTimer);
        this.downloadTimer = null;
      }, 2000);

      const store = useStore();
      const accountID = Cookies.get("AccountIDMD5") || store?.userInfo?.accountID;
      if (!accountID && !store.isGlobalWeb) {
        console.log("未登录");
        store.showLogin = true;
        return;
      }
      this.isDownloading = true;
      BusinessService.getAlbumZipFileUrl(this.id, store.lang)
        .then((res) => {
          console.log("包装书下载成功：", res);
          this.isDownloading = false;
          util.downloadFile(res.fileUrl);
        })
        .catch((err) => {
          console.log("包装书下载失败：", err);
          this.isDownloading = false;
          this.showToast(err.resultMessage);
        });
    },
    onTapBaiDu() {
      window.open(this.bookInfo.baiduDirLink);
    },
    onPrev(index) {
      console.log("on prev", index);
      if (index == 0) return;
      this.imgCurrentIndex = index - 1;
      this.onTapImage(this.imgCurrentIndex);
    },
    onNext(index) {
      console.log("on next", index);
      if (index == this.bookInfo.picList.length - 1) return;
      this.imgCurrentIndex = index + 1;
      this.onTapImage(this.imgCurrentIndex);
    },
    setTitle() {
      const store = useStore();
      store.titleArray = [
        {
          name: this.$t("资源下载"),
          path: this.getRelativePath("/list"),
          backIdx: -1,
        },
        {
          name: this.$t("点读书"),
          path: this.getRelativePath("/list?id=1"),
          backIdx: -1,
        },
        {
          name: this.bookInfo.bookName || this.bookInfo.albumName,
        },
      ];
    },
    onTapHideDescribePopup() {
      this.isShowDescribePopup = false;
      this.canWheel();
    },
    onTapShowDescribePopup() {
      this.isShowDescribePopup = true;
      this.noWheel();
    },
    onTapHelp() {
      const { lang, isGlobalWeb } = this.store;
      let host = getHost("appHelpCenter");
      let path = this.getRelativePath("/help");
      let url = `${host}/QuestionH5/Content/` + getUsageID(lang) + "?Transfer_Template=Frame";
      if (isGlobalWeb & (lang != "zh")) url += `&Region=Overseas&AppLang=${lang}`;
      url = path + "/" + encodeURIComponent(url) + "?title=" + this.$t("点读包下载说明");
      this.$router.push(url);
    },
    //点击缩略图
    onTapImage(idx, e) {
      console.log("当前索引", idx);
      this.imgCurrentIndex = idx;
      // 通过动态 ref 名称访问元素
      const element = this.$refs[`img_${idx}`];
      if (element && element[0]) {
        const parentElement = element[0].parentElement;
        const leftOffset = element[0].offsetLeft;
        parentElement.scrollTo({ left: leftOffset, behavior: "smooth" });
      }
    },
    noWheel() {
      const store = useStore();
      //store.isNoWheel = true;
    },
    canWheel() {
      const store = useStore();
      //store.isNoWheel = false;
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
  },
  mounted() {
    let route = useRoute();
    let params = route.params.id.split("-");
    this.id = params[0];
    this.isAlbum = parseInt(params[1]);
    this.initData();

    // setTimeout(()=>{
    //   document.getElementById('img-list').scrollLeft=20;
    // },3000)
  },
};
</script>

<style lang="less" scoped>
.flex-wrap {
  display: flex;
  align-items: center;
}

.wrapper {
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;

  .wd_content {
    min-height: 450px;

    > .wd_info {
      display: flex;
      width: 100%;
      padding: 0 36px;

      .img-wrap {
        width: 366px;
        height: 366px;
        margin-right: 25px;
        background: #f0f0f0;
        position: relative;
        &.rtl {
          margin-left: 25px;
          margin-right: unset;
        }

        .img-error {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(255, 255, 255, 0.7);
        }
      }

      .wd_title_wrap {
        display: flex;
        flex-direction: column-reverse;
        height: 70px;
        margin-top: 38px;

        .wd_title {
          font-size: 24px;
          color: #333333;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .wd_desc {
        display: flex;
        align-items: center;
        margin-top: 16px;
        color: #666;
        font-size: 16px;
        .wd_time,
        .wd_size {
          color: #666;
          line-height: 1;
        }

        .wd_line {
          width: 1px;
          height: 12px;
          background-color: rgba(151, 151, 151, 0.24);
          margin: 0 8px;
          margin-top: 2px;
        }
      }
      .wd_down {
        width: 204px;
        height: 54px;
        font-size: 18px;
        line-height: 54px;
        background: #fa885a;
        border-radius: 8px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #f26636;
        }
      }

      .wd_bd {
        width: 204px;
        height: 54px;
        font-size: 18px;
        line-height: 54px;
        background: #5cc8fa;
        border-radius: 8px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #3ec2ff;
        }
      }

      .wd_img {
        width: 366px;
        margin-top: 5px;
        display: flex;
        padding-bottom: 10px;
        cursor: pointer;
        position: relative;

        &.rtl {
          .list-shadow-left {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
            left: auto;
            right: 0;
            .icon-arrow {
              transform: rotateY(180deg);
            }
          }
          .list-shadow-right {
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
            right: auto;
            left: 0;
            .icon-arrow {
              transform: rotateY(180deg);
            }
          }
        }

        .wd_img_list {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          overflow-x: auto;
          user-select: none;
          gap: 6px;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          &::-webkit-scrollbar {
            display: none; /* Safari 和 Chrome */
          }

          .action {
            border: 2px solid #fa885a;
          }

          .img {
            width: 56px;
            height: 56px;
            border-radius: 4px;
            flex-shrink: 0;
          }
        }
      }

      .list-shadow-left {
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 56px;
        cursor: pointer;
        z-index: 10;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-arrow {
          width: 20px;
          height: 20px;
        }
      }

      .list-shadow-right {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
        height: 56px;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 10;
        .icon-arrow {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .down-wrap {
    border-top: 1px solid #f6f6f6;
    margin-top: 82px;
    padding: 0 36px;

    .title {
      font-size: 20px;
      color: #000e48;
      margin-top: 20px;
      margin-bottom: 15px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
    }

    .item-down {
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px 5px 0px;
      border-bottom: 1px rgba(217, 217, 217, 0.7) solid;
      position: relative;
      cursor: pointer;

      &:hover {
        border-bottom: 1px rgba(217, 217, 217, 0.4) solid;
        background-color: rgba(230, 240, 255, 0.16);
      }

      > div:nth-of-type(1) {
        width: 82px;
        font-size: 16px;
        text-align: center;
        color: #999999;
      }

      &:hover .item-name {
        color: #5cc8fa;
      }

      > div:nth-of-type(2) {
        flex: 1;
        font-size: 16px;
        color: #999999;
      }

      .download-progress {
        width: 87px;
        height: 35px;
        margin: auto;
      }

      .item-img {
        width: 90px;
        height: 90px;
        margin-right: 16px;
        display: flex;
        align-items: center;
      }

      .item-name {
        color: #333333;
        font-size: 20px;
      }

      &:hover .item-name {
        color: #009bf5;
      }

      .item-sizeTime {
        display: flex;
        align-items: center;
        margin-top: 3px;
        gap: 40px;
      }

      .item-size {
        font-size: 14px;
      }

      .item-time {
        font-size: 14px;
      }

      .item-downLoad {
        width: 160px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span,
        > div {
          width: 92px;
          height: 36px;
          line-height: 36px;
          background-color: #fa885a;
          border-radius: 8px;
          text-align: center;
          color: #ffffff;
          font-size: 16px;

          &:hover {
            background-color: #f26636;
          }
        }
      }
    }
  }

  .wd_noContent {
    margin: 0px auto;
    padding-top: 120px;
  }
}

.icon_series {
  height: 22px;
  padding-left: 8px;
  padding-right: 10px;
  background: #5cc8fa;
  border-radius: 4px 16px 16px 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  text-align: center;
  display: inline-block;

  &::after {
    content: "";
    margin-bottom: 10px;
    width: 1px;
    height: 10px;
  }
  &.rtl {
    border-radius: 16px 4px 4px 16px;
  }
}

.error-wrap {
  margin-top: 16px;
  padding: 0 20px 0 11px;
  display: flex;
  position: relative;

  img {
    width: 48px;
    height: 36px;
  }

  p {
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-left: 11px;
  }

  div {
    position: absolute;
    width: 222px;
    height: 30px;
    background-color: rgba(92, 200, 250, 0.12);
    border-radius: 8px;
    bottom: 12px;
    left: 0;
    z-index: 0;
  }
}

.wrap-flex {
  display: flex;
  align-items: center;
  margin-top: 33px;
  gap: 20px;
}

.describe-popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .d_wrap {
    width: 606px;
    height: 440px;
    background: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 24px 8px 35px 35px;
    position: relative;

    .title {
      width: 321px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .line {
      width: 536px;
      height: 1px;
      border: 1px solid rgba(217, 217, 217, 0.7);
      margin: 16px 0;
    }

    .content {
      width: 560px;
      height: 320px;
      font-size: 16px;

      font-weight: 400;
      color: #999999;
      line-height: 24px;
      overflow: scroll;
      padding-right: 20px;
      word-break: break-all;
      white-space: pre-wrap;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #e6f0ff;
        border-radius: 3px;
      }
    }

    .bg {
      width: 230px;
      height: 67px;
      right: 35px;
      top: 2px;
      position: absolute;
      z-index: 0;
    }

    .btn-close {
      width: 32px;
      height: 32px;
      top: 10px;
      right: 10px;
      position: absolute;
      z-index: 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.no-more {
  height: 22px;
  font-size: 16px;

  font-weight: 400;
  color: rgba(153, 153, 153, 0.6);
  line-height: 22px;
  margin: 24px auto 56px;
  text-align: center;
}

.zip-explain {
  height: 56px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 16px;
  padding: 8px 13px;
  box-sizing: border-box;
  position: relative;

  p {
    font-size: 14px;

    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 90px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #f5f5f5;
  }

  &.rtl::after {
    left: auto;
    right: 90px;
  }

  .nav {
    color: #5cc8fa;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
  }
}

.nozip-explain {
  min-width: 375px;
  display: inline-block;
  height: 34px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 16px;
  padding: 7px 16px;
  box-sizing: border-box;
  position: relative;

  p {
    font-size: 14px;

    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 90px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #f5f5f5;
  }

  &.rtl::after {
    left: auto;
    right: 90px;
  }
}

.orange {
  color: #ff7841;
}
</style>

<template>
  <div class="w_class">
    <div :class="target == '全部' ? 'active' : ''" @click="onTapItem('全部')">
      全部{{ type == "user" ? "类型" : type == "age" ? "年龄" : "主题" }}
    </div>
    <div
      :class="target == item.name ? 'active' : ''"
      @click="onTapItem(item.name)"
      v-for="item in list"
      v-bind:key="item"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
const userList = [
  {
    name: "儿歌",
    typeList: [
      {
        name: "日常认知",
      },
      {
        name: "习惯培养",
      },
      {
        name: "安全意识",
      },
      {
        name: "自我保护",
      },
      {
        name: "英文儿歌",
      },
      {
        name: "国学经典",
      },
      {
        name: "古诗词",
      },
      {
        name: "动物百科",
      },
      {
        name: "奇幻冒险",
      },
    ],
  },
  {
    name: "故事",
    typeList: [
      {
        name: "日常认知",
      },
      {
        name: "安全意识",
      },
      {
        name: "自我保护",
      },
      {
        name: "童话寓言",
      },
      {
        name: "动物百科",
      },
      {
        name: "侦探推理",
      },
      {
        name: "奇幻冒险",
      },
      {
        name: "幽默益智",
      },
      {
        name: "成语认知",
      },
    ],
  },
];
const ageList = [
  {
    name: "0-3岁",
  },
  {
    name: "4-6岁",
  },
  {
    name: "7岁+",
  },
];
const typeList = [
  {
    name: "日常认知",
  },
  {
    name: "习惯培养",
  },
  {
    name: "安全意识",
  },
  {
    name: "自我保护",
  },
  {
    name: "英文儿歌",
  },
  {
    name: "国学经典",
  },
  {
    name: "古诗词",
  },
  {
    name: "童话寓言",
  },
  {
    name: "动物百科",
  },
  {
    name: "侦探推理",
  },
  {
    name: "奇幻冒险",
  },
  {
    name: "幽默益智",
  },
  {
    name: "成语认知",
  },
];
import { useRoute } from "vue-router";
export default {
  name: "selection-list",
  data() {
    return {
      list: [],
      target: "全部",
      router: {},
    };
  },
  props: {
    type: String,
    tag: String,
    user: String,
  },
  watch: {
    tag(str) {
      console.log("修改toast:", str);
      if (str == "全部" && this.target != "全部") {
        this.target = "全部";
      }
    },
    user(str) {
      if (this.type == "type") {
        let list = [];
        for (let user of userList) {
          if (user.name == str) {
            list = user.typeList;
            break;
          }
        }
        this.list = list.length ? list : typeList;
        // if (str != "全部") {
        //   this.target = "全部";
        // }
      }
    },
  },
  methods: {
    onTapItem(e) {
      let data = {
        type: this.type,
        name: e,
      };
      if (this.type == "user" && e != "全部") {
        let { typeTag } = this.router.query;
        let isReset = true;
        for (let user of userList) {
          if (user.name == e) {
            for (let type of user.typeList) {
              if (type.name == typeTag) {
                isReset = false;
              }
            }
          }
        }
        data.isReset = isReset;
      }
      this.$emit("onClickSection", data);
      this.target = e;
    },
  },
  mounted() {
    let route = (this.router = useRoute());
    let { userTag, ageTag, typeTag } = route.query;
    switch (this.type) {
      case "user":
        this.list = userList;
        if (userTag) {
          this.target = userTag;
        }
        break;
      case "age":
        this.list = ageList;
        if (ageTag) {
          this.target = ageTag;
        }
        break;
      case "type":
        this.list = typeList;
        for (let user of userList) {
          if (user.name == this.user) {
            this.list = user.typeList;
            break;
          }
        }
        if (!this.list) {
          this.list = typeList;
        }
        if (typeTag) {
          this.target = typeTag;
        }
        break;
    }
  },
};
</script>

<style lang="less" scoped>
.wrapper .w_class {
  display: flex;
  align-items: center;
  margin-left: 35px;
  flex-wrap: wrap;
}
.wrapper .w_class .active {
  background-color: #5cc8fa;
  color: white;
}
.wrapper .w_class div {
  margin-top: 16px;
  min-width: 80px;
  margin-right: 12px;
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  color: #999999;
  padding: 0 6px;
  cursor: pointer;
}
</style>

<template>
  <div class="header">
    <div class="h_content">
      <router-link :to="getRelativePath('/')" class="logo" v-rtl>
        <img :src="$t('logo')" />
      </router-link>
      <div class="h_link" v-rtl>
        <router-link v-if="!store.isGlobalWeb" :to="getRelativePath('/')" active-class="active">首页</router-link>
        <router-link :to="getRelativePath('/list')" active-class="active" :class="{ active: getActiveRouteName() == 'LIST' }">{{ $t("资源下载") }}</router-link>
        <router-link :to="getRelativePath('/help-list')" active-class="active" :class="{ active: getActiveRouteName() == 'HELP' }">{{ $t("帮助与服务") }}</router-link>
        <a :href="store.isGlobalWeb ? 'https://www.babybus.com/global/ar/about' : 'https://www.babybus.com/business'" target="_blank">{{ $t("联系我们") }}</a>
        <template v-if="!store.isGlobalWeb">
          <!-- 国内版展示登录 -->
          <div class="user-login" v-if="store.userInfo && store.userInfo.accountID">
            <!--这是登录-->
            <div class="avatar" @click.stop="showLoginOut">
              <img v-if="store.userInfo.avatarUrl" :src="store.userInfo.avatarUrl" />
              <img v-else src="https://iot.babybus.com/BookPoint/BookPic/20220615/3b1bff335d1e4ba292f6d050bfeef29f.png?w=38&h=38" />
            </div>
            <div class="login-out active" v-if="isShowLoginOut" @click="loginOut">
              <div class="triangle"></div>
              <img src="https://dingfile.babybus.com/DingFile/DingBusFile/20201209/fdcf9c5977a143bd9b709a0712298127.svg" />
              <span>退出登录</span>
            </div>
          </div>
          <div class="user-login" v-else @click="showLogin">
            <div class="d_status">
              <span class="status">未登录</span>
              <img src="https://iot.babybus.com/BookPoint/BookPic/20220615/f52e2590ecd54b6ca1d7219963372215.png?w=114&amp;h=114" alt="" />
            </div>
          </div>
        </template>
        <div v-else class="language" :class="{ show: showLangMenu }" @click.stop="toggleLangMenu">
          <!-- 海外版展示语言 -->
          <img class="icon-lang" src="@/assets/images/icon_lang.svg" />
          <span class="lang-text" v-rtl>
            {{ store.lang.toUpperCase() }}
          </span>
          <img class="icon-arrow" src="@/assets/images/icon_arrow_down_lang.svg" />
          <div class="option-lang-list" v-rtl>
            <ul>
              <li class="option-lang" @click.stop="onSwitchLang('ar', '/list')"><span class="tag">AR</span>العربية</li>
              <li class="option-lang" @click.stop="onSwitchLang('zh', '/')"><span class="tag">CN</span>简体中文</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "@/store";
import BusinessService from "@/network/service";
import Cookies from "js-cookie";
export default {
  name: "header",

  data() {
    return {
      isLogin: false,
      isShowLoginOut: false,
      showLangMenu: false,
    };
  },
  setup() {
    const store = useStore();
    console.log("store:", store);
    return {
      store,
    };
  },
  computed: {
    getActiveRouteName() {
      const _path = this.$route.path;
      return () => {
        if (_path === "/help-list" || _path.includes("/help/")) {
          return "HELP";
        } else if (_path === "/list" || _path.includes("/book/") || _path.includes("/audio/")) {
          return "LIST";
        }
      };
    },
  },
  watch: {
    $route(to, from) {},
  },
  methods: {
    getUserInfo() {
      const accountID = Cookies.get("AccountIDMD5");
      const avatarUrl = Cookies.get("AvatarUrl");
      const nickName = Cookies.get("UserName");

      if (accountID) {
        console.log("已登录", accountID);
        const store = useStore();
        store.userInfo = {
          accountID,
          avatarUrl,
          nickName,
        };
      }
    },
    getLoginResult() {
      BusinessService.getLoginResult()
        .then((res) => {
          console.log("getLoginResult Success:", res);
          let userInfo = {
            accountID: Cookies.get("AccountID"),
            nickName: Cookies.get("UserName"),
            avatarUrl: Cookies.get("AvatarUrl"),
          };

          // 读取不到cookie的时候从接口返回处拿
          if (!userInfo.accountID && res.accountInfo) {
            console.log("读取不到cookie的时候从接口返回处拿");
            userInfo = res.accountInfo;
          }
          const store = useStore();
          store.userInfo = userInfo;
        })
        .catch((err) => {
          console.error("getLoginResult Error:", err);
          // this.showToast(err.resultMessage);
        });
    },
    showLogin() {
      const store = useStore();
      store.showLogin = true;
    },
    hideLogin() {
      const store = useStore();
      store.showLogin = false;
    },
    showLoginOut() {
      this.isShowLoginOut = !this.isShowLoginOut;
    },
    // 登出
    loginOut() {
      BusinessService.loginOut()
        .then((res) => {
          console.log("loginOut Success:", res);
          if (res.data.resultCode == "0") {
            const store = useStore();
            let userInfo = store.userInfo;
            for (let key in userInfo) {
              Cookies.remove(key);
            }

            store.userInfo = {};
            this.isShowLoginOut = false;
          }
        })
        .catch((err) => {
          console.error("loginOut Error:", err);
          this.showToast(err.resultMessage);
        });
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },

    // 展开/收起语言菜单
    toggleLangMenu() {
      this.showLangMenu = !this.showLangMenu;
    },
    // 切换语言
    onSwitchLang(lang, path) {
      if (this.store.lang == lang) {
        this.toggleLangMenu();
        return;
      }
      this.store.lang = lang;
      const { isGlobalWeb } = this.store;
      if (isGlobalWeb) {
        // 当前在海外版
        this.$router.push(this.getRelativePath(path));
      } else {
        // 跳回国内
        const { origin } = location;
        let url = origin + this.getRelativePath(path);
        // 此处必须location.preplace,才能DNS解析域名代理到国外/海外服务器
        location.href = url;
      }
    },
    // 关闭切换语言遮罩
    handleClickOutside(event) {
      const el_lang = document.querySelector(".language");
      if (el_lang && !(el_lang === event.target || el_lang.contains(event.target))) {
        this.showLangMenu = false;
      }
      const el_logout = document.querySelector(".login-out");
      if (el_logout && !(el_logout === event.target || el_logout.contains(event.target))) {
        this.isShowLoginOut = false;
      }
    },
  },
  mounted() {
    if (!this.store.isGlobalWeb) this.getLoginResult();
    document.body.addEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 75px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 2px 2px 2px rgba(246, 246, 246, 0.8);

  .h_content {
    width: 100%;
    max-width: 1610px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 180px;
      height: 44px;
      display: inline-flex;
      margin-left: 100px;
      img {
        width: 100%;
        height: 100%;
      }
      &.rtl {
        margin-left: unset;
        margin-right: 100px;
      }
    }
  }

  .h_link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    float: right;
    margin-right: 70px;

    &.rtl {
      margin-right: unset;
      margin-left: 70px;
    }

    > a {
      // width: 96px;
      padding: 0 22.5px;
      color: #999999;
      font-size: 16px;
      text-align: center;
      white-space: nowrap;
      transition: all 0.2s;
      &.active {
        color: #000e48 !important;
        // font-weight: 600;
        transform: scale(1.1);
      }
    }

    .user-login {
      position: relative;
      padding: 14px 0;
      cursor: pointer;

      &.user-login {
        width: 96px;
        color: #999999;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
      }

      .d_status {
        position: relative;

        span {
          width: 48px;
          height: 28px;
          line-height: 23px;
          position: absolute;
          right: 2px;
          top: -12px;
          color: white;
          font-size: 11px;
          background: url(https://iot.babybus.com/BookPoint/BookPic/20220615/716989cd99d848f5a150f3443a211b14.png?w=144&h=84) no-repeat;
          background-size: 100% 100%;
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }

      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        // background: url(https://iot.babybus.com/BookPoint/BookPic/20220615/3b1bff3….png?w=38&h=38) no-repeat center center;
        background-size: contain;
        overflow: hidden;
        margin: 0 auto;

        img {
          height: 38px;
          width: 38px;
          margin: 0 auto;
        }
      }

      .login-out {
        position: absolute;
        left: -24px;
        bottom: -50px;
        width: 112px;
        height: 48px;
        background: #fff;
        line-height: 1;
        text-align: center;
        display: none;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        z-index: 99;

        &.active {
          display: flex;
        }

        .triangle {
          width: 0;
          height: 0;
          top: -7px;
          position: absolute;
          right: 31px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #fff;
        }

        img {
          width: 24px;
          height: 24px;
        }

        span {
          padding-left: 4px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(25, 31, 37, 0.72);
        }
      }
    }

    .language {
      display: flex;
      align-items: center;
      margin-left: 12px;
      cursor: pointer;
      position: relative;
      .lang-text {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        margin: 0 4px 0 8px;
        &.rtl {
          margin: 0 8px 0 4px;
        }
      }
      &.show {
        .option-lang-list {
          height: auto;
        }
      }
      .option-lang-list {
        position: absolute;
        left: 0;
        top: 57px;
        flex-direction: column;
        background-color: #fff;
        border-radius: 8px;
        min-width: 104px;
        height: 0;
        overflow: hidden;
        transition: all 0.2s;
        &.rtl {
          right: 0;
          left: auto;
        }
        .option-lang {
          font-size: 16px;
          padding: 20px 12px;
          display: flex;
          align-items: center;
          gap: 8px;
          white-space: nowrap;
          &:hover {
            background: #ddf2fb;
          }
          .tag {
            width: 32px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #5cc8fa;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>

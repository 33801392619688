<template>
  <div class="searchTag" v-if="pageType == 'search' && recordCount > 0">
    <div class="line"></div>
    找到{{ recordCount }}个点读书
    <div class="line"></div>
  </div>
  <div class="all_block">
    <BookItem v-for="item in list" v-bind:key="item.bookID" :data="item" @showDownloadSign="showDownloadSign"></BookItem>
    <div class="wd_noContent" v-if="isRequest && list && list.length == 0">
      <Error type="none" />
    </div>
  </div>
  <PageBar v-if="pageCount" :pageCount="pageCount" :pageIndex="pageIndex" @setPageIndex="setPageIndex"></PageBar>
</template>

<script>
import BookItem from "../book-item/book-item.vue";
import PageBar from "../page-bar/page-bar.vue";
import Error from "../error/error.vue";
export default {
  name: "book-list",
  components: {
    BookItem,
    PageBar,
    Error,
  },
  props: {
    list: Array,
    pageCount: Number,
    pageIndex: Number,
    recordCount: Number,
    isRequest: Boolean,
    pageType: String,
  },
  methods: {
    setPageIndex(index) {
      this.$emit("setPageIndex", index);
    },
    showDownloadSign(e) {
      this.$emit("showDownloadSign", e);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.all_block {
  display: flex;
  flex-wrap: wrap;
  min-height: 570px;
  padding: 24px 44px 48px;
}
.wd_noContent {
  margin: 120px auto;
}
.searchTag {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cfcfcf;
  line-height: 22px;
  margin: 16px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchTag .line {
  width: 13px;
  height: 1px;
  background-color: #cfcfcf;
  margin: 0 8px;
}
</style>

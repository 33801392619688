import { createI18n } from "vue-i18n";
import ar from "@/lang/ar";
import zh from "@/lang/zh";

const message = {
  ar: ar,
  zh: zh,
};
const i18n = createI18n({
  locale: "zh", //默认是中文
  legacy: false, //解决报错的
  globalInjection: true, // 全局注册$t方法
  messages: message,
});

export default i18n;

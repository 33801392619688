<template>
  <div class="wrapper" v-rtl>
    <PageClass v-if="!store.isGlobalWeb" :id="pageClassId" @onClickPageClass="onClickPageClass" />
    <Search
      v-if="!store.isGlobalWeb"
      :pageClassId="pageClassId"
      :isSearching="isSearching"
      :freshSearch="freshSearch"
      @onTapSearch="onTapSearch"
      @onInputSearch="onInputSearch"
      @onInputClear="onInputClear"
    ></Search>
    <div class="list-main" v-show="pageClassId == 0">
      <Banner />
      <BookList
        :list="bookList[pageIndex]"
        :pageCount="pageCount"
        :pageIndex="pageIndex"
        :isRequest="isRequestBookList"
        :pageType="pageType"
        :recordCount="bookSearchCount"
        @setPageIndex="setPageIndex"
        @showDownloadSign="showDownloadSign"
      />
    </div>
    <div class="list-main" v-show="pageClassId == 1">
      <SectionList type="user" :tag="userTag" @onClickSection="onClickSection" />
      <SectionList type="age" :tag="ageTag" @onClickSection="onClickSection" />
      <SectionList type="type" :user="userTag" :tag="typeTag" @onClickSection="onClickSection" />
      <AudioList
        :list="albumList[pageIndex]"
        :pageCount="pageCount"
        :pageIndex="pageIndex"
        :isRequest="isRequestAlbumList"
        :pageType="pageType"
        :recordCount="audioSearchCount"
        @setPageIndex="setPageIndex"
      />
    </div>
  </div>
  <!-- 下载提示 -->
  <div class="download-sign-wrap" v-if="isShowDownloadSign">
    <div class="content">
      <div class="title">开始下载啦~</div>
      <div class="dec">
        下载完成后，请检查文件大小是否不小于<span class="orange">{{ downLoadSignFileSize }}MB</span>
      </div>
      <div class="button-wrap">
        <div class="btn" @click="hideDownloadSign">我知道了</div>
        <div class="btn" @click="dontShow">不再提示</div>
      </div>
    </div>
  </div>
</template>

<script>
import PageClass from "@/components/page-class/page-class.vue";
import Search from "@/components/search/search.vue";
import Banner from "@/components/banner/banner.vue";
import BookList from "@/components/book-list/book-list.vue";
import AudioList from "@/components/audio-list/audio-list.vue";
import SectionList from "@/components/selection-list/selection-list.vue";
import BusinessService from "@/network/service";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { getAreaID, getCollectID } from "../../network/config";
const BookAreaID = getAreaID("book");
const AudioCollectID = getCollectID("audio");
export default {
  name: "list",
  components: {
    PageClass,
    Search,
    Banner,
    BookList,
    AudioList,
    SectionList,
  },
  data() {
    return {
      pageClassId: 0,
      bookList: [],
      albumList: [],
      isRequestBookList: false,
      isRequestAlbumList: false,
      isSearching: false,
      pageIndex: 0,
      pageCount: 0,
      pageType: "collect",
      userTag: "全部",
      ageTag: "全部",
      typeTag: "全部",
      searchKey: "",
      bookSearchCount: 0,
      audioSearchCount: 0,
      freshSearch: false,
      isShowDownloadSign: false,
      downLoadSignFileSize: 0,
    };
  },
  setup() {
    const store = useStore();
    console.log("store:", store);
    return {
      store,
    };
  },
  methods: {
    showDownloadSign(e) {
      // let isShow = sessionStorage.getItem(`hideDownloadSign`);
      // if (!isShow) {
      //   this.isShowDownloadSign = true;
      //   this.downLoadSignFileSize = e;
      // }
    },
    hideDownloadSign() {
      this.isShowDownloadSign = false;
    },
    dontShow() {
      this.hideDownloadSign();
      sessionStorage.setItem(`hideDownloadSign`, true);
    },
    onTapSearch() {
      if (!this.searchKey.length) return;
      this.pageIndex = 0;
      this.isSearching = true;
      if (this.pageClassId == 0) {
        this.searchBookInfo(this.searchKey);
      } else {
        this.searchAlbumInfo(this.searchKey);
      }
      this.scrollToTop();
    },
    //获取搜索点读书列表
    searchBookInfo(searchKey) {
      let pagesize = 16;
      let pageIndex = this.pageIndex + 0;
      this.bookSearchCount = 0;
      this.isRequestBookList = false;

      this.setQuery({
        page: this.pageIndex + 1,
        keyword: this.searchKey,
      });
      BusinessService.searchBookInfo({
        keyword: searchKey,
        pagesize: pagesize,
        pageindex: pageIndex,
      })
        .then((res) => {
          console.log("获取搜索图包列表成功：", res);
          for (let album of res.searchResultList) {
            album.bookName = album.objectName;
            album.albumID = album.objectID;
            album.isAlbum = album.objectType == 1 ? 0 : 1;
          }
          this.bookList[pageIndex] = res.searchResultList;
          this.isRequestBookList = true;
          if (res.pageCount) {
            this.pageCount = parseInt(res.pageCount);
          }
          this.pageType = "search";
          this.isSearching = false;
        })
        .catch((e) => {
          console.error("获取搜索图包列表失败：", e);
          this.isSearching = false;
          this.bookList[pageIndex] = [];
          this.isRequestBookList = true;
          this.showToast(e.resultMessage);
        });
    },
    //获取搜索专辑列表
    searchAlbumInfo(searchKey) {
      let pagesize = 16;
      let pageIndex = this.pageIndex + 0;
      this.audioSearchCount = 0;
      this.isRequestAlbumList = false;
      this.setQuery({
        page: this.pageIndex + 1,
        keyword: this.searchKey,
      });
      BusinessService.searchAlbumInfo({
        keyword: searchKey,
        pagesize: pagesize,
        pageindex: pageIndex,
      })
        .then((res) => {
          console.log("获取搜索专辑列表成功", res);
          this.albumList[pageIndex] = res.albumInfoList;
          this.isRequestAlbumList = true;
          if (pageIndex == 0) {
            let recordCount = (this.audioSearchCount = parseInt(res.recordCount));
            this.pageCount = Math.ceil(recordCount / pagesize);
          }
          this.pageType = "search";
          this.isSearching = false;
        })
        .catch((e) => {
          console.error("获取搜索专辑列表失败", e);
          this.isSearching = false;
          this.albumList[pageIndex] = [];
          this.isRequestAlbumList = true;
          this.showToast(e.resultMessage);
        });
    },
    //获取集合数据
    getBookList() {
      let params = {};
      params.areaID = BookAreaID;
      params.pagesize = 16;
      params.pageClassId = this.pageClassId + 0;
      params.pageindex = this.pageIndex + 0;
      params.lang = this.store.lang;
      this.isRequestBookList = false;
      this.setQuery({
        page: this.pageIndex + 1,
        keyword: this.searchKey,
      });
      BusinessService.getAreaInfo(params)
        .then((res) => {
          console.log("获取点读包数据成功", res);
          this.bookList[params.pageindex] = this.disposeCollectInfo(res.data, params.pageClassId);
          this.isRequestBookList = true;
          if (params.pageindex == 0) {
            let recordCount = parseInt(res.recordCount);
            this.pageCount = Math.ceil(recordCount / params.pagesize);
            sessionStorage.setItem(`BookPageCount`, this.pageCount);
          } else {
            if (!this.pageCount) {
              this.pageCount = parseInt(sessionStorage.getItem(`BookPageCount`)) || 0;
            }
          }
          this.pageType = "collect";
        })
        .catch((e) => {
          console.error("获取点读包数据失败", e);
          this.showToast(e.resultMessage);
        });
    },
    //获取音频集合
    getAudioList() {
      let params = {};
      let pageIndex = this.pageIndex + 0;
      let pageClassId = this.pageClassId + 0;
      params = {
        collectID: AudioCollectID,
        userTag: this.userTag,
        ageTag: this.ageTag,
        typeTag: this.typeTag,
        pagesize: 12,
      };
      this.setQuery({
        userTag: this.userTag,
        ageTag: this.ageTag,
        typeTag: this.typeTag,
        page: this.pageIndex + 1,
        keyword: this.searchKey,
      });
      this.isRequestAlbumList = false;
      params.pageClassId = pageClassId;
      params.pageindex = pageIndex;
      BusinessService.getCollectInfo(params)
        .then((res) => {
          console.log("获取音频集合成功", res);
          this.albumList[pageIndex] = this.disposeCollectInfo(res.data, pageClassId);
          this.isRequestAlbumList = true;
          if (pageIndex == 0) {
            let recordCount = parseInt(res.recordCount);
            this.pageCount = Math.ceil(recordCount / params.pagesize);
            sessionStorage.setItem(`AudioPageCount`, this.pageCount);
          } else {
            if (!this.pageCount) {
              this.pageCount = parseInt(sessionStorage.getItem(`AudioPageCount`)) || 0;
            }
          }

          this.pageType = "collect";
        })
        .catch((e) => {
          console.error("获取音频集合失败", e);
          this.showToast(e.resultMessage);
          if (pageClassId == 0) {
            this.bookList[pageIndex] = [];
            this.isRequestBookList = true;
          } else {
            this.albumList[pageIndex] = [];
            this.isRequestAlbumList = true;
          }
        });
    },
    onInputSearch(key) {
      this.searchKey = key;
      console.log(key);
    },
    onInputClear() {
      this.searchKey = "";
      if (this.pageType == "search") {
        this.pageIndex = 0;
        if (this.pageClassId == 0) {
          this.getBookList();
        } else {
          this.getAudioList();
        }
      }
    },

    //处理collectInfo数据
    disposeCollectInfo(arr, pageClassId) {
      let list = [];
      for (let item of arr) {
        if (pageClassId == 0) {
          list.push({
            ...item.fieldData,
            isAlbum: item.dataCode == "BookPoint_AlbumInfo" ? "1" : "0",
          });
        } else {
          list.push({
            ...item.fieldData,
            picUrl: item.picUrl,
            title: item.title,
          });
        }
      }
      return list;
    },
    setPageIndex(idx) {
      if (idx == this.pageIndex) return;
      this.pageIndex = idx;
      if (this.pageType == "search") {
        if (this.pageClassId == 0) {
          this.searchBookInfo(this.searchKey);
        } else {
          this.searchAlbumInfo(this.searchKey);
        }
      } else {
        if (this.pageClassId == 0) {
          this.getBookList();
        } else {
          this.getAudioList();
        }
      }

      this.scrollToTop();
    },
    scrollToTop() {
      document.documentElement.scrollTop = 0;
    },
    // 点击tag
    onClickSection({ type, name, isReset }) {
      console.log("点击音频tag：", name);
      switch (type) {
        case "user":
          if (this.userTag == name) {
            return;
          }
          this.userTag = name;
          if (isReset) {
            this.typeTag = "全部";
          }
          break;
        case "age":
          if (this.ageTag == name) {
            return;
          }
          this.ageTag = name;
          break;
        case "type":
          if (this.typeTag == name) {
            return;
          }
          this.typeTag = name;
          break;
      }
      this.pageCount = 0;
      this.pageIndex = 0;
      this.getAudioList();
    },
    // 点击顶部tag
    onClickPageClass(e) {
      this.pageClassId = e;
      this.userTag = "全部";
      this.ageTag = "全部";
      this.typeTag = "全部";
      this.pageIndex = 0;
      this.pageCount = 0;
      this.freshSearch = !this.freshSearch;
      if (this.pageClassId == 0) {
        this.getBookList();
      } else {
        this.getAudioList();
      }
      this.scrollToTop();

      this.$router.replace({
        query: {
          id: e + 1,
        },
      });
    },
    setTitle() {
      const store = useStore();
      store.titleArray = [];
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
    setQuery(data) {
      const { ...query } = this.$route.query;
      for (let key in data) {
        query[key] = data[key];
      }
      this.$router.replace({ query: { ...query } });
    },
  },
  mounted() {
    let route = useRoute();
    let { id, keyword, page, userTag, ageTag, typeTag } = route.query;
    if (id) {
      if (id == "1") {
        this.pageClassId = 0;
      } else if (id == "2") {
        this.pageClassId = 1;
      }
    }

    if (page) {
      this.pageIndex = parseInt(page) - 1;
    }
    if (userTag) {
      this.userTag = userTag;
    }
    if (ageTag) {
      this.ageTag = decodeURIComponent(ageTag);
    }
    if (typeTag) {
      this.typeTag = typeTag;
    }
    if (keyword) {
      this.searchKey = keyword;
      if (this.pageClassId == 0) {
        this.searchBookInfo(this.searchKey);
      } else {
        this.searchAlbumInfo(this.searchKey);
      }
    } else {
      if (this.pageClassId == 0) {
        this.getBookList();
      } else {
        this.getAudioList();
      }
    }

    this.setTitle();
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 1000px;
  background-color: #fafafa;
  &.rtl {
    margin-top: 0;
  }
  .list-main {
    overflow: hidden;
  }
}

.download-sign-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-sign-wrap .content {
  background: #ffffff;
  border-radius: 20px;
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.download-sign-wrap .content .title {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #333333;
}
.download-sign-wrap .content .dec {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #666666;
  margin-top: 16px;
}
.download-sign-wrap .content .orange {
  color: #ff7841;
}
.download-sign-wrap .content .button-wrap {
  display: flex;
  margin-top: 24px;
}
.download-sign-wrap .content .button-wrap .btn {
  width: 152px;
  height: 44px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}
.btn:nth-child(1) {
  background: #64c8ff;
  color: #fff;
  margin-right: 16px;
}
.btn:nth-child(2) {
  border: 1px solid #dddddd;
  color: #999;
}
</style>

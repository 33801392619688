<template>
  <audio
    src=""
    ref="audio"
    @ended="onEnded"
    @pause="onPause"
    @play="onPlay"
    @error="onError"
    @timeupdate="onTimeUpdate"
    @loadedmetadata="onLoadedmetadata"
    :volume="audioVolume"
  ></audio>
  <div class="player-wrap" v-show="audioList && audioList.length">
    <div class="player-bar">
      <div class="player-img">
        <Image :src="audioList && audioList[audioIndex] && audioList[audioIndex].coverUrl" />
      </div>
      <div class="play-action">
        <div class="play-prev" @click="prev"></div>
        <div class="play-pause-btn">
          <i class="icon icon-play" v-if="!isPlaying" @click="onTapPlay"></i>
          <i class="icon icon-pause" v-if="isPlaying" @click="onTapPause"></i>
        </div>
        <div class="play-next" @click="next"></div>
      </div>

      <div class="player-control-wrap">
        <div class="player-name">
          {{ audioList && audioList[audioIndex] && audioList[audioIndex].audioName }}
        </div>
        <div class="player-tool">
          <div class="slider" data-direction="horizontal" ref="audioProgressWrap">
            <div class="slider-touch-wrap" @click="initProgressBarPoint"></div>
            <div class="progress" ref="audioProgress" data-type="progress" style="width: 0%">
              <div class="pin" ref="audioProgressPoint"></div>
            </div>
          </div>
          <div class="player-time">
            <span class="current">{{ currentTimeAfterFormat }}</span> /
            <span class="total">{{ durationAfterFormat }}</span>
          </div>
          <div class="player-vol">
            <div class="volume-btn active" @click.stop="onTapVolume">
              <i class="icon icon-vol" :class="audioVolume == 0 ? 'icon-mute' : 'icon-vol'"></i>
            </div>
            <div class="volume-controls">
              <div class="slider" ref="volumeProgressWrap">
                <div class="progress" ref="volumeProgress" style="height: 100%">
                  <div class="pin" ref="volumeProgressPoint"></div>
                </div>
              </div>
              <div class="volume-touch-wrap" ref="volumeControls"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/utils/utils";
import BusinessService from "@/network/service";
import BgAudioManager from "@/utils/audio-manager";
import Cookies from "js-cookie";
import { useStore } from "@/store";
import Image from "../image/image.vue";
export default {
  name: "player",
  components: {
    Image,
  },
  data() {
    return {
      __id: "",
      isPlaying: false,
      isDragging: false,
      isVolume: false,
      isInitProgressBarDrag: false,
      isShowVolume: false,
      currentTime: 0,
      currentTimeAfterFormat: "00:00",
      duration: 0,
      durationAfterFormat: "00:00",
      audioIndex: 0,
      audioList: [],
      currentAudio: {},
      audioVolume: 1,
      defaultVolume: 1,
    };
  },

  methods: {
    onPlay() {
      this.isPlaying = true;
      BgAudioManager.setData({
        currentAudio: this.audioList[this.audioIndex],
        status: "play",
      });
      BgAudioManager.dispatchStatusChangedListener();
      BgAudioManager.dispatchCurrentAudioChangedListener();
    },
    onPause() {
      this.isPlaying = false;
      BgAudioManager.setData({ status: "pause" });
      BgAudioManager.dispatchStatusChangedListener("pause");
    },
    onError() {
      this.isPlaying = false;
      BgAudioManager.setData({ status: "pause" });
      BgAudioManager.dispatchStatusChangedListener("pause");
    },
    getAudioUrl() {
      let audio = this.currentAudio;
      BusinessService.getAudioUrl({
        audioID: audio.audioID,
        albumID: audio.albumID,
      })
        .then((res) => {
          console.log("获取音频地址成功：", res);
          this.audioList[this.audioIndex].url = res.url;
          this.$refs.audio.src = res.url;
          this.$refs.audio.play();
        })
        .catch((err) => {
          console.log("获取音频地址失败：", err);
          this.showToast(err.resultMessage);
        });
    },
    // 初始化音频进度的拖拽逻辑
    initProgressBarDrag() {
      if (this.isInitProgressBarDrag) {
        return;
      }
      this.isInitProgressBarDrag = true;
      this.$refs.audioProgressPoint.addEventListener("mousedown", this.onMouseDownByAudio, false);
      this.$refs.volumeControls.addEventListener("mousedown", this.onMouseDownByVolume, false);
      window.addEventListener("mousemove", this.onMouseMove, false);
      this.$refs.volumeControls.addEventListener("mousemove", this.onMouseMoveByVolume, false);
      window.addEventListener("mouseup", this.onMouseUp, false);
    },
    onMouseDownByAudio() {
      this.isDragging = true;
    },
    onMouseDownByVolume() {
      this.isVolume = true;
    },
    onMouseMove(event) {
      if (this.isDragging) {
        let pageX = event.pageX;

        // 超出左边
        if (pageX < this.$refs.audioProgressWrap.offsetLeft) {
          // 设置点点
          this.$refs.audioProgressPoint.style.left = 0;
          // 设置进度条
          this.$refs.audioProgress.style.width = 0;
          // 设置当前时间
          this.currentTime = 0;
          // 设置当前时间（格式化后）
          this.currentTimeAfterFormat = util.formatSeconds(this.currentTime);
          return;
        }

        // 超出右边
        if (pageX > this.$refs.audioProgressWrap.offsetLeft + this.$refs.audioProgressWrap.offsetWidth) {
          // 设置点点
          this.$refs.audioProgressPoint.style.left = this.$refs.audioProgressWrap.offsetWidth - this.$refs.audioProgressPoint.offsetWidth + "px";
          // 设置进度条
          this.$refs.audioProgress.style.width = this.$refs.audioProgressWrap.offsetWidth + "px";
          // 设置当前时间，0.1解决有的浏览器播放完了进度还会再走
          this.currentTime = this.duration - 0.1;
          // 设置当前时间（格式化后）
          this.currentTimeAfterFormat = util.formatSeconds(this.currentTime);
          return;
        }

        this.setPointPosition(pageX);
        // 设置进度条
        this.$refs.audioProgress.style.width = pageX - this.$refs.audioProgressWrap.offsetLeft + "px";
        // 设置当前时间
        this.currentTime = (this.$refs.audioProgress.offsetWidth / this.$refs.audioProgressWrap.offsetWidth) * this.duration;
        // 设置当前时间（格式化后）
        this.currentTimeAfterFormat = util.formatSeconds(this.currentTime);
      }
    },
    onMouseMoveByVolume(event) {
      if (!this.isVolume) return;

      let pageY = event.offsetY;
      // 超出上方
      if (pageY < this.$refs.volumeProgressWrap.offsetTop) {
        if (this.audioVolume == 1) return;
        // 设置进度条
        this.$refs.volumeProgress.style.height = this.$refs.volumeProgressWrap.offsetHeight + "px";
        console.log("超出上方");
        this.audioVolume = 1;
        this.defaultVolume = 1;
        return;
      }
      // 超出下方
      if (pageY > this.$refs.volumeProgressWrap.offsetTop + this.$refs.volumeProgressWrap.offsetHeight) {
        if (this.audioVolume == 0) return;
        console.log("超出下方");
        // 设置进度条
        this.$refs.volumeProgress.style.height = 0;
        this.audioVolume = 0;
        this.defaultVolume = 0;
        return;
      }

      let volumePercent = 1 - (pageY - this.$refs.volumeProgressWrap.offsetTop) / this.$refs.volumeProgressWrap.offsetHeight;
      if (this.audioVolume == volumePercent) return;
      this.audioVolume = volumePercent;
      this.defaultVolume = volumePercent;
      // 设置进度条
      this.$refs.volumeProgress.style.height = this.$refs.volumeProgressWrap.offsetHeight - (pageY - this.$refs.volumeProgressWrap.offsetTop) + "px";
    },
    onMouseUp() {
      if (this.isDragging) {
        this.$refs.audio.currentTime = this.currentTime;
        this.isDragging = false;
        if (!this.isPlaying) {
          this.play();
        }
      }
      //设置音频位置
      if (this.isVolume) {
        this.isVolume = false;
      }
    },
    // 初始化音频进度的点击逻辑
    initProgressBarPoint(event) {
      // 设置当前时间
      this.currentTime = ((event.pageX - this.$refs.audioProgressWrap.offsetLeft) / this.$refs.audioProgressWrap.offsetWidth) * this.duration;
      // 设置播放位置
      this.$refs.audio.currentTime = this.currentTime;
      this.setPointPosition(event.pageX);

      // 设置进度条
      this.$refs.audioProgress.style.width = event.pageX - this.$refs.audioProgressWrap.offsetLeft + "px";

      // 设置当前时间（格式化后）
      this.currentTimeAfterFormat = util.formatSeconds(this.currentTime);

      if (!this.isPlaying) {
        this.play();
      }
    },
    // 设置点点位置
    setPointPosition(pageX) {
      // 设置点点
      this.$refs.audioProgressPoint.style.left = pageX - this.$refs.audioProgressPoint.offsetWidth / 2 - this.$refs.audioProgressWrap.offsetLeft + "px";
    },
    play(idx) {
      if (typeof idx == "number") {
        this.audioIndex = idx;
      }
      //暂停播放
      if (this.currentAudio.audioID == this.audioList[this.audioIndex].audioID) {
        this.$refs.audio.play();
        return;
      }

      if (this.isPlaying) {
        this.pause();
      }

      let audio = (this.currentAudio = this.audioList[this.audioIndex]);
      if (audio.url) {
        this.$refs.audio.src = audio.url;
        this.$refs.audio.play();
      } else {
        this.getAudioUrl();
      }
    },
    pause() {
      this.$refs.audio.pause();
    },
    next() {
      if (this.isPlaying) {
        this.pause();
      }
      if (this.audioIndex >= this.audioList.length - 1) {
        this.audioIndex = 0;
      } else {
        this.audioIndex = this.audioIndex + 1;
      }
      this.play();
    },
    prev() {
      if (this.isPlaying) {
        this.pause();
      }
      if (this.audioIndex == 0) {
        this.audioIndex = this.audioList.length - 1;
      } else {
        this.audioIndex = this.audioIndex - 1;
      }
      this.play();
    },
    onTapPlay() {
      this.play();
    },
    onTapPause() {
      this.pause();
    },
    onEnded() {
      BgAudioManager.setData({ status: "pause" });
      BgAudioManager.dispatchStatusChangedListener("pause");
      this.next();
    },
    onTimeUpdate(e) {
      this.currentTimeAfterFormat = util.formatSeconds(e.srcElement.currentTime);

      if (!this.isDragging) {
        this.currentTime = e.srcElement.currentTime;

        this.$refs.audioProgressPoint.style.left =
          (this.currentTime / this.duration) * this.$refs.audioProgressWrap.offsetWidth - this.$refs.audioProgressPoint.offsetWidth / 2 + "px";

        this.$refs.audioProgress.style.width = (this.currentTime / this.duration) * this.$refs.audioProgressWrap.offsetWidth + "px";
      }
    },
    onLoadedmetadata(e) {
      this.durationAfterFormat = util.formatSeconds(e.srcElement.duration);
      this.duration = e.srcElement.duration;
    },
    onTapVolume() {
      this.audioVolume = this.audioVolume > 0 ? 0 : this.defaultVolume > 0 ? this.defaultVolume : 1;
      if (this.audioVolume == 0) {
        this.$refs.volumeProgress.style.height = 0;
      } else {
        this.$refs.volumeProgress.style.height = this.audioVolume * this.$refs.volumeProgressWrap.offsetHeight + "px";
      }
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
    initListener() {
      if (this.__id) return;
      this.__id = "player_" + +new Date();
      BgAudioManager.addAudioListChangedListener(this.__id, (list) => {
        this.audioList = list;
        this.initProgressBarDrag();
      });
      BgAudioManager.addAudioPlayListener(this.__id, (idx, type) => {
        if (type == "play") {
          this.play(idx);
        } else {
          this.pause();
        }
      });
    },
  },
  mounted() {
    this.initListener();
  },
  beforeUnmount() {
    if (this.$refs.audioProgressPoint) this.$refs.audioProgressPoint.removeEventListener("mousedown", this.onMouseDownByAudio);
    if (this.$refs.volumeProgressPoint) this.$refs.volumeProgressPoint.removeEventListener("mousedown", this.onMouseDownByVolume);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
  },
};
</script>

<style lang="less" scoped>
.player-wrap {
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 100;
  bottom: 0px;
  height: 54px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1px;
  padding: 6px 0;
}
.player-wrap .player-bar {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
}
.player-wrap .player-img {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  overflow: hidden;
  // float: left;
  margin-right: 30px;
}
.player-wrap .player-img img {
  height: 100%;
  width: 100%;
}
.player-wrap .play-action {
  width: 92px;
  height: 100%;
  // float: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.player-wrap .play-action .play-prev {
  float: left;
  height: 32px;
  width: 33px;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://educate-res.babybus.com/Educate/ResourceFile/20220413/cc2427045cf542ae969d70a387203af2.png);
  cursor: pointer;
}
.player-wrap .play-action .play-prev:hover {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/698acbdae0c546249431d596d267c15c.png);
}
.player-wrap .play-pause-btn {
  cursor: pointer;
  float: left;
  position: relative;
  height: 32px;
  width: 32px;
}
.player-wrap .play-pause-btn .icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 32px;
  width: 32px;
}
.player-wrap .play-pause-btn .icon.icon-play {
  background-image: url(https://educate-res.babybus.com/Educate/ResourceFile/20220413/4cab2843b0334f6ba56580c65236cd31.png);
}
.player-wrap .play-action .icon.icon-play:hover {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/9891d9f6ea1b4846a5068e40de31c98d.png);
}
.player-wrap .play-pause-btn .icon.icon-pause {
  background-image: url(https://educate-res.babybus.com/Educate/ResourceFile/20220413/e54a3a2fabe74119aa21e532493a6fcb.png);
}
.player-wrap .play-action .icon.icon-pause:hover {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/78618494adff48f092705d9f39d855b3.png);
}
.player-wrap .play-action .play-next {
  float: left;
  height: 32px;
  width: 33px;
  margin-left: 12px;
  /* margin-right: 12px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://educate-res.babybus.com/Educate/ResourceFile/20220413/49516bc9df0942f68d983a1400025e68.png);
  cursor: pointer;
}
.player-wrap .play-action .play-next:hover {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/4844d857cf024238a82bf4a0cce9da3a.png);
}
.player-wrap .player-control-wrap {
  // float: left;
  width: 870px;
  height: 100%;
  margin-left: -150px;
  padding-left: 186px;
}
.player-wrap .player-control-wrap .player-name {
  width: 520px;
  height: 18px;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 3px;
}

.player-wrap .player-control-wrap .player-tool {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 8px;
}
.player-wrap .player-control-wrap .player-tool .slider .progress {
  display: block;
  height: 100%;
  width: 0;
  background: #64c8ff;
  border-radius: 2px;
  position: relative;
  overflow: visible;
  pointer-events: none;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.player-wrap .player-control-wrap .player-tool .player-time {
  width: 80px;
  text-align: center;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
  margin-left: -15px;
  margin-top: -3px;
}
.player-wrap .player-control-wrap .player-tool span {
  cursor: default;
}
.player-wrap .player-control-wrap .player-tool .player-vol {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  margin-top: -22px;
}
.player-wrap .player-control-wrap .player-tool .player-vol .volume-btn {
  cursor: pointer;
}
.player-wrap .player-control-wrap .player-tool .player-vol .volume-btn.active .icon-vol {
  // display: none;
}
.player-wrap .player-control-wrap .player-tool .player-vol .icon.icon-vol {
  background-image: url(https://educate-res.babybus.com/Educate/ResourceFile/20220413/cf3882f873dd4a23a7d5837f9bf71ced.png);
  background-repeat: no-repeat;
}
.player-wrap .player-control-wrap .player-tool .player-vol:hover .icon.icon-vol {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/81fe5c22a4f6496bb05c8635da5156c8.png);
  background-repeat: no-repeat;
}
.player-wrap .player-control-wrap .player-tool .player-vol .icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
}

.player-wrap .player-control-wrap .player-tool .player-vol .icon.icon-mute {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/96e87d72a18b4e8a98f8f0a06f2fe2bc.png);
}
.player-wrap .player-control-wrap .player-tool .player-vol:hover .icon.icon-mute {
  background-image: url(https://course-data-img.babybus.com/CourseData/CoursePic/20220815/ce7a8fd4c2944fc78e3f00fecc38564b.png);
}
.player-wrap .player-control-wrap .player-tool .player-vol .icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
}

.player-wrap .player-control-wrap .player-tool .player-vol .volume-controls {
  width: 32px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0px;
  bottom: 45px;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}
.player-wrap .player-control-wrap .player-tool .player-vol .volume-controls .volume-touch-wrap {
  position: absolute;
  width: calc(100% + 10px);
  height: 100%;
  top: 0;
  left: -5px;
}
.player-wrap .player-control-wrap .player-tool .player-vol:hover .volume-controls {
  display: block;
}
.player-wrap .player-control-wrap .player-tool .player-vol:after {
  content: "";
  display: block;
  height: 50px;
  width: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.player-wrap .player-control-wrap .player-tool .player-vol .volume-controls .slider {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 2px;
  height: 100px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  position: relative;
  margin-left: 14px;
}
.player-wrap .player-control-wrap .player-tool .slider {
  width: -webkit-calc(100% - 140px);
  width: -moz-calc(100% - 140px);
  width: calc(100% - 140px);
  height: 2px;
  position: relative;
  background: #000000;
  /* border-radius: 2px; */
}
.player-wrap .player-control-wrap .player-tool .player-vol .volume-controls .slider .progress {
  bottom: 0;
  height: 100%;
  width: 2px;
  position: absolute;
  margin-bottom: 0;
}
.player-wrap .player-control-wrap .player-tool .slider .progress {
  display: block;
  height: 100%;
  width: 0;
  background: #64c8ff;
  border-radius: 2px;
  position: relative;
  overflow: visible;
  pointer-events: none;
}
.player-wrap .player-control-wrap .player-tool .player-vol .volume-controls .slider .progress .pin {
  left: -2px;
  top: -3px;
}
.player-wrap .player-control-wrap .player-tool .slider .progress .pin {
  cursor: pointer;
  position: absolute;
  pointer-events: all;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  right: 1px;
  top: -2px;
  background: #ffffff;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

.slider-touch-wrap {
  width: 100%;
  height: 6px;
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 0;
}
</style>

class BgAudioManager {
    constructor() {
        this.statusChangeListeners = new Map();
        this.currentAudioChangeListeners = new Map();
        this.audioListChangeListeners = new Map();
        this.audioPlayListeners = new Map();
        this.init();
    }

    init() {
        this.data = {
            status: '',
            currentAudio: {},
            audioList: [],
            albumID: 0,
        }
    }
    setData(data) {
        this.data = {
            ...this.data,
            ...data
        }
    }

    //播放器状态
    addStatusChangedListener(playerId, listener) {
        this.safeSetPropToMap(this.statusChangeListeners, playerId, listener);
    }

    removeStatusChangedListener(playerId) {
        this.safeDeleteMapProp(this.statusChangeListeners, playerId);
    }

    dispatchStatusChangedListener() {
        this.statusChangeListeners.forEach(element => {
            element(this.data.status);
        });
    }

    //当前播放音频切换
    addCurrentAudioChangedListener(playerId, listener) {
        this.safeSetPropToMap(this.currentAudioChangeListeners, playerId, listener);

    }

    removeCurrentAudioChangedListener(playerId) {
        this.safeDeleteMapProp(this.currentAudioChangeListeners, playerId);
    }

    dispatchCurrentAudioChangedListener() {
        this.currentAudioChangeListeners.forEach(element => {
            element(this.data.currentAudio);
        });
    }

    //播放列表切换
    addAudioListChangedListener(playerId, listener) {
        this.safeSetPropToMap(this.audioListChangeListeners, playerId, listener);

    }

    removeAudioListChangedListener(playerId) {
        this.safeDeleteMapProp(this.audioListChangeListeners, playerId);
    }

    dispatchAudioListChangedListener() {
        console.log('this.audioListChangeListeners:',this.audioListChangeListeners)
        this.audioListChangeListeners.forEach(element => {
            element(this.data.audioList);
        });
    }

    //播放音频
    addAudioPlayListener(playerId, listener) {
        this.safeSetPropToMap(this.audioPlayListeners, playerId, listener);
    }

    removeAudioPlayListener(playerId) {
        this.safeDeleteMapProp(this.audioPlayListeners, playerId);
    }

    dispatchAudioPlayListener(idx, type = 'play') {
        this.audioPlayListeners.forEach(element => {
            element(idx, type);
        });
    }


    safeSetPropToMap(map, key, value) {
        if (!map) {
            map = new Map();
        }

        map.set(key, value);
    }

    safeDeleteMapProp(map, key) {
        map.has(key) && map.delete(key);
    }

}

export default new BgAudioManager();
<template>
  <div class="wrapper" style="background-color: #fff">
    <Title></Title>
    <p v-html="content" v-rtl style="width: 100%; height: 100%; padding: 0 120px"></p>
    <div class="wd_noContent" v-show="showNetError">
      <Error type="net" />
    </div>
  </div>
</template>

<script>
import Title from "../../components/title/title.vue";
import BusinessService from "@/network/service";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import BgAudioManager from "@/utils/audio-manager";
import Error from "../../components/error/error.vue";
import { getRelativePath } from "@/mixins/rPath";
export default {
  name: "helper-detail",
  components: {
    Title,
    Error,
  },
  data() {
    return {
      url: "",
      content: "",
      timer: null,
      showNetError: false,
    };
  },
  methods: {
    initData() {
      this.getDetail();
    },
    getDetail() {
      BusinessService.getQuestionList(this.url)
        .then((res) => {
          this.content = res;
          this.addListener();
        })
        .catch((err) => {
          if (err.code && err.code == "ERR_NETWORK") {
            this.showToast(err.resultMessage);
            this.showNetError = true;
          } else {
            this.content = err;
            this.addListener();
          }
        });
    },
    addListener() {
      this.timer = setTimeout(() => {
        var videos = document.querySelectorAll("video");
        for (var video of videos) {
          video.addEventListener("play", this.onVideoPlay);
        }

        var audios = document.querySelectorAll("audio");
        for (var audio of audios) {
          audio.addEventListener("play", this.onAudioPlay);
        }
      }, 1000);
    },
    removeListener() {
      if (this.timer) clearTimeout(this.timer);
      var videos = document.querySelectorAll("video");
      for (var video of videos) {
        video.removeEventListener("play", this.onVideoPlay);
      }

      var audios = document.querySelectorAll("audio");
      for (var audio of audios) {
        audio.removeEventListener("play", this.onAudioPlay);
      }
    },
    onVideoPlay() {
      BgAudioManager.dispatchAudioPlayListener(-1, "pause");
    },
    onAudioPlay() {
      var videos = document.querySelectorAll("video");
      for (var video of videos) {
        video.pause();
      }
    },
    setTitle(productName, productID, title) {
      const store = useStore();
      if (productID && productID != "undefined") {
        store.titleArray = [
          {
            name: this.$t("帮助与服务"),
            path: this.getRelativePath("/help-list"),
            backIdx: -1,
          },
          {
            name: productName,
            path: `${this.getRelativePath("/help-list")}?id=${productID}`,
            backIdx: -1,
          },
          {
            name: title,
          },
        ];
      } else {
        store.titleArray = [
          {
            name: this.$t("帮助与服务"),
            path: this.getRelativePath("/help-list"),
            backIdx: -1,
          },
          {
            name: title,
          },
        ];
      }
    },
    showToast(msg, type = "error") {
      const store = useStore();
      store.toast = {
        isShow: true,
        msg: msg || this.$t("netError"),
        type,
      };
    },
  },
  mounted() {
    let route = useRoute();
    this.url = decodeURIComponent(route.params.url).replace("http://", "https://");
    this.initData();
    let productName = decodeURIComponent(route.query.productname);
    let productID = decodeURIComponent(route.query.productid);
    let title = decodeURIComponent(route.query.title);
    this.setTitle(productName, productID, title);
  },
  unmounted() {
    this.removeListener();
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  // margin-left: calc((100% - 1000px) / 2) !important;
  padding: 8px;
  overflow: hidden;
}

p {
  margin: auto;
  padding: auto;
  /deep/ ol,
  /deep/ ul {
    padding-left: 20px;
  }
  &.rtl {
    /deep/ ol,
    /deep/ ul {
      padding-left: 0;
      padding-right: 20px;
    }
  }
}

.wd_noContent {
  width: 100%;
  margin: 120px auto;
  text-align: center;
}
.wd_noContent > img {
  width: 300px;
  height: 211px;
}
.wd_noContent > section {
  color: #999999;
  font-size: 16px;
}
</style>

import { useStore } from "@/store";

export function getRelativePath(path) {
  const store = useStore();
  const { lang, isGlobalWeb } = store;

  let rPath = path;
  // 对于中文不需要前缀处理
  if (lang !== "zh") {
    rPath = `${isGlobalWeb ? "/global" : ""}${lang ? "/" + lang : ""}${path == "/" ? "" : path}`;
  }
  return rPath;
}

<template>
  <div @mousewheel="mousewheel" class="container-layout">
    <Header></Header>
    <router-view></router-view>
    <Footer :showPlayer="!!audioList.length"></Footer>
    <Login v-if="store.showLogin"></Login>
    <Toast v-show="store.toast.isShow" :msg="store.toast.msg" :type="store.toast.type"></Toast>
    <Player v-show="audioList.length"></Player>
  </div>
</template>

<script>
import Header from "@/components/header/header.vue";
import Footer from "@/components/footer/footer.vue";
import Login from "@/components/login/login.vue";
import Player from "@/components/player/player.vue";
import Toast from "@/components/toast/toast.vue";
import BgAudioManager from "@/utils/audio-manager";
import { useStore } from "@/store";
export default {
  name: "app",
  data() {
    return {
      audioList: [],
    };
  },
  components: {
    Header,
    Footer,
    Login,
    Player,
    Toast,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  methods: {
    mousewheel(e) {
      if (this.store.isNoWheel) {
        e.preventDefault();
      }
    },
  },
  mounted() {
    this.__id = "app_" + +new Date();
    BgAudioManager.addAudioListChangedListener(this.__id, (list) => {
      this.audioList = list;
    });
  },
};
</script>

<style lang="less">
.container-layout {
  min-width: 1000px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrapper {
    flex: 1;
  }
}
</style>

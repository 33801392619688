<template>
  <div class="list-router-wrap">
    <router-link i :to="getRelativePath('/help-list')">
      <img class="banner-img" :src="require(`@/assets/images/${store.lang}/icon_guide_help.png`)" alt="" />
    </router-link>
  </div>
</template>

<script>
import { getRelativePath } from "@/mixins/rPath";
import { useStore } from "@/store";
export default {
  name: "banner",
  components: {},
  data() {
    const store = useStore();
    console.log("store:", store);
    return {
      store,
    };
  },
  mounted() {},
};
</script>

<style lang="less">
.list-router-wrap {
  height: 40px;
  display: flex;
  padding: 0 50px;
  margin-top: 30px;
}
.banner-img {
  width: auto;
  height: 40px;
}
</style>
